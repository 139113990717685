<template>
  <form-wizard
    ref="refFormWizard"
    color="#fb8500"
    :title="null"
    :subtitle="null"
    finish-button-text="Submit"
    back-button-text="Previous"
    hide-buttons
    class="checkout-form-wizard steps-transparent"
  >

    <tab-content
      title="Flow"
      icon="feather icon-grid"
      class=".nav-pill-success"
    >
      <order-flow-step-flows
        @set-select-flow="setSelectedFlow"
      />
    </tab-content>

    <tab-content
      title="Aansluitingen"
      icon="feather icon-home"
      class=".nav-pill-success"
    >
      <order-flow-step-supply-address
        :order-data="orderData"
        :address="address"
        :charging-point-products="chargingPointProducts"
        :show-step-supply-address-spinner="showStepSupplyAddressSpinner"
        @set-selected-supply-address="setSelectedSupplyAddress"
        @set-order-data="setConnections"
        @back-step="stepBack"
        @set-relation="setRelation"
      />
    </tab-content>

    <tab-content
      v-if="chargingPointProducts.length > 0 && hasChargingPointProducts()"
      title="Laadpaal product"
      icon="feather icon-package"
    >
      <order-flow-step-charging-point-products
        :charging-point-products="chargingPointProducts"
        :spinner="showStepSupplyAddressSpinner"
        @set-select-charging-point-product="setSelectedChargingPointProducts"
        @back-step="stepBack"
      />
    </tab-content>

    <tab-content
      title="Product"
      icon="feather icon-package"
    >
      <order-flow-step-products
        :draft-calculation="draftCalculation"
        @set-select-product="setSelectedProduct"
        @back-step="stepBack"
      />
    </tab-content>

    <tab-content
      title="Klantgegevens"
      icon="feather icon-users"
      class=".nav-pill-success"
    >
      <order-flow-step-relation
        :contact="address.contact"
        :selected-relation="selectedRelation"
        :product-id="orderData.productID"
        :selected-supply-address="selectedSupplyAddress"
        :is-business="orderData.isBusiness"
        @set-relation="setRelation"
        @next-step="formWizardNextStep"
        @back-step="stepBack"
      />
    </tab-content>

    <tab-content
      title="Overzicht"
      icon="feather icon-shopping-cart"
      class=".nav-pill-success"
    >
      <order-flow-step-summary
        :order-data="orderData"
        :relation="selectedRelation"
        :selected-flow="selectedFlow"
        :draft-calculation="draftCalculation"
        @back-step="stepBack"
      />
    </tab-content>
  </form-wizard>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import orderFlowStoreModule from '@/views/pages/order-flow/orderFlowStoreModule'
import OrderFlowStepFlows from '@/views/pages/order-flow/OrderFlowStepFlows.vue'
import OrderFlowStepSupplyAddress from '@/views/pages/order-flow/OrderFlowStepSupplyAddress.vue'
import OrderFlowStepProducts from '@/views/pages/order-flow/OrderFlowStepProducts.vue'
import OrderFlowStepRelation from '@/views/pages/order-flow/OrderFlowStepRelation.vue'
import OrderFlowStepChargingPointProducts from '@/views/pages/order-flow/OrderFlowStepChargingPointProducts.vue'
import OrderFlowStepSummary from '@/views/pages/order-flow/OrderFlowStepSummary.vue'

export default {
  components: {
    OrderFlowStepChargingPointProducts,
    FormWizard,
    TabContent,
    OrderFlowStepFlows,
    OrderFlowStepSupplyAddress,
    OrderFlowStepRelation,
    OrderFlowStepProducts,
    OrderFlowStepSummary,
  },
  data() {
    return {
      orderData: {
        productID: null,
        product: null,
        chargingPointProductID: null,
        relationID: null,
        connections: [],
        flowID: null,
        signatureSVG: null,
        signType: null,
        isBusiness: 0,
        isSoho: 0,
      },
      selectedSupplyAddress: {
        postalCode: null,
        houseNumber: null,
        houseNumberAddition: null,
        street: null,
        city: null,
      },
      selectedProduct: {
        id: null,
        name: null,
      },
      selectedChargingPointProducts: null,
      selectedRelation: {},
      selectedFlow: {},
      relation: {},
      products: [],
      chargingPointProducts: [],
      address: {
        correspondenceAddressIsSame: 1,
        supplyAddress: {
          postalCode: null,
          houseNumber: null,
          houseNumberAddition: null,
          street: null,
          city: null,
          province: null,
          type: 0,
          business: 0,
          coc: null,
          ean: {
            electricity: [],
            gas: [],
          },
        },
        correspondenceAddress: {
          postalCode: null,
          houseNumber: null,
          houseNumberAddition: null,
          street: null,
          city: null,
          province: null,
        },
        contact: {
          firstName: null,
          middleName: null,
          lastName: null,
          phone: null,
          email: null,
          gender: 'male',
          birthDate: null,
          iban: null,
          ibanAscription: null,
          endDateContract: null,
          companyName: null,
          coc: null,
          contactPerson: null,
        },
      },
      draftCalculation: [],
      showStepSupplyAddressSpinner: false,
    }
  },
  methods: {
    getDraftCalculation() {
      this.showStepSupplyAddressSpinner = true
      store.dispatch('apps-order-flow-checkout/getDraftCalculation', this.orderData).then(response => {
        // eslint-disable-next-line prefer-destructuring
        this.draftCalculation = response.data
        this.showStepSupplyAddressSpinner = false
        this.formWizardNextStep()
      }).catch(() => {
        this.showStepSupplyAddressSpinner = false
      })
    },
    hasChargingPointProducts() {
      if (this.orderData.connections.length > 0) {
        return this.orderData.connections.some(obj => obj.isChargingPoint === true)
      }

      return false
    },
    getAddress() {
      const somethingChanged = []

      if (!this.supplyAddress.postalCode || !this.supplyAddress.houseNumber) {
        return
      }

      if (this.supplyAddress.postalCode) {
        somethingChanged.push(true)
      }

      if (this.supplyAddress.houseNumber) {
        somethingChanged.push(true)
      }

      if (this.supplyAddress.houseNumberAddition) {
        somethingChanged.push(true)
      }

      if (somethingChanged.includes(true)) {
        this.fetchAddress(this.supplyAddress)
      }
    },
    setSelectedSupplyAddress(data) {
      this.selectedSupplyAddress = data
    },
    hasChargingPoint() {
      return this.orderData.connections.some(obj => obj.isChargingPoint === true)
    },
    async setConnections(data) {
      this.showStepSupplyAddressSpinner = true
      await this.setOrder(data)
      if (!this.hasChargingPoint()) {
        await this.getDraftCalculation(this.orderData)
      }

      if (this.hasChargingPoint()) {
        this.showStepSupplyAddressSpinner = true
        await this.fetchChargingPointProducts({ active: true, isBusiness: this.orderData.isBusiness, isChargingPointProposition: true })
      }
    },
    setSelectedFlow(flow) {
      this.selectedFlow = flow
      this.orderData.flowID = flow.id

      if (flow.canSignSignature) {
        this.orderData.signType = 'SIGNATURE'
      }

      if (flow.canOfferEmail) {
        this.orderData.signType = 'EMAIL'
      }

      this.formWizardNextStep()
    },
    setSelectedProduct(product) {
      this.selectedProduct = product
      this.orderData.product = product
      this.orderData.productID = product.id
      this.orderData.isSoho = product.isSohoProposition
      this.formWizardNextStep()
    },
    async setSelectedChargingPointProducts(product) {
      this.selectedChargingPointProducts = product
      this.orderData.chargingPointProductID = product.id
      await this.getDraftCalculation(this.orderData)
    },
    setRelation(data) {
      this.selectedRelation = data.relation
      this.orderData.relationID = data.relation.id
      if (data.next) {
        this.formWizardNextStep()
      }
    },
    setOrder(data) {
      this.orderData = data
    },
    fetchProducts(queryParams) {
      store.dispatch('apps-order-flow-checkout/fetchProducts', queryParams)
        .then(response => {
          this.products = response.data.items
          this.showStepSupplyAddressSpinner = false
        }).catch(() => {
          this.showStepSupplyAddressSpinner = false
        })
    },
    fetchChargingPointProducts(queryParams) {
      store.dispatch('apps-order-flow-checkout/fetchProducts', queryParams)
        .then(response => {
          this.chargingPointProducts = response.data.items
          this.showStepSupplyAddressSpinner = false
          this.formWizardNextStep()
        })
        .catch(() => {
          this.showStepSupplyAddressSpinner = false
        })
    },
    stepBack() {
      this.formWizardPreviousStep()
    },
  },
  setup() {
    const ORDER_FLOW_APP_STORE_MODULE_NAME = 'apps-order-flow-checkout'

    // Register module.
    if (!store.hasModule(ORDER_FLOW_APP_STORE_MODULE_NAME)) store.registerModule(ORDER_FLOW_APP_STORE_MODULE_NAME, orderFlowStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ORDER_FLOW_APP_STORE_MODULE_NAME)) store.unregisterModule(ORDER_FLOW_APP_STORE_MODULE_NAME)
    })

    const refFormWizard = ref(null)

    const formWizardNextStep = () => {
      refFormWizard.value.nextTab()
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }

    const formWizardPreviousStep = () => {
      refFormWizard.value.prevTab()
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }

    return {
      refFormWizard,
      formWizardNextStep,
      formWizardPreviousStep,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
[dir] .vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked{
  box-shadow: 0 3px 6px 0 rgb(211 17 69 / 40%) !important;
}

.ecommerce-application .product-checkout.list-view {
  grid-template-columns: 2fr !important;
}
</style>
