import { ref } from '@vue/composition-api'

export default function useOrderFlow() {
  const refElectricityEANTable = ref(null)
  const electricityEanTableColumns = [
    { label: 'EAN', key: 'ean', sortable: false },
    { label: 'Type', key: 'marketSegment', sortable: false },
    { label: 'Gebruiksdoel', key: 'bagPurpose', sortable: false },
    { label: 'Meter type', key: 'meterType', sortable: false },
    { label: 'Verbruik', key: 'usage', sortable: false },
    { label: 'Teruglevering', key: 'returnElectricitySingle', sortable: false },
  ]

  const refetchElectricityEANData = () => {
    refElectricityEANTable.value.refresh()
  }

  const refGasTable = ref(null)
  const GasTableColumns = [
    { label: 'EAN', key: 'ean', sortable: false },
    { label: 'Type', key: 'marketSegment', sortable: false },
    { label: 'Gebruiksdoel', key: 'bagPurpose', sortable: false },
    { label: 'Verbruik', key: 'usage', sortable: false },
  ]

  const refetchGasEANData = () => {
    refGasTable.value.refresh()
  }

  const refChargingStationTable = ref(null)
  const chargingStationTableColumns = [
    { label: 'Awesems ID', key: 'chargingPointID', sortable: false },
    { label: 'Type auto', key: 'chargingPointCarType', sortable: false },
    { label: 'Wanneer is de laadpaal geplaatst', key: 'chargingPointPeriodType', sortable: false },
  ]

  const refetchChargingStationData = () => {
    refChargingStationTable.value.refresh()
  }

  const getUsageType = type => {
    if (type === 'SMALLCONSUMER') return 'Kleinverbruik'
    if (type === 'LARGECONSUMER') return 'Grootverbruik'
    if (type === 'ARTICLE') return 'Artikel aansluiting'
    return ''
  }

  const chargingPointPeriodOptions = [
    { label: 'Korter dan een jaar', value: 'SHORT' },
    { label: 'Langer dan een jaar', value: 'LONG' },
  ]

  const getChargingPointPeriod = period => {
    if (period === 'SHORT') return 'Korter dan een jaar'
    if (period === 'LONG') return 'Langer dan een jaar'
    return ''
  }

  const chargingPointCarTypeOptions = [
    { label: 'Klein', value: 'SMALL' },
    { label: 'Medium', value: 'MEDIUM' },
    { label: 'Groot', value: 'BIG' },
  ]

  const getChargingPointCarType = type => {
    if (type === 'SMALL') return 'Klein'
    if (type === 'MEDIUM') return 'Medium'
    if (type === 'BIG') return 'Groot'
    return ''
  }

  return {
    refElectricityEANTable,
    electricityEanTableColumns,
    refetchElectricityEANData,
    refGasTable,
    GasTableColumns,
    refetchGasEANData,
    refChargingStationTable,
    refetchChargingStationData,
    chargingStationTableColumns,
    getUsageType,
    chargingPointPeriodOptions,
    chargingPointCarTypeOptions,
    getChargingPointPeriod,
    getChargingPointCarType,
  }
}
