import axios from '@axios'
import axiosDefaultConfig from '@core/app-config/axiosDefaultConfig'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchFlows(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}flows`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    searchCocRegister(ctx, coc) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}search/kvk?q=${coc.q}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAddress(ctx, data) {
      let url = ''
      if (data.houseNumberAddition) {
        url = `${axiosDefaultConfig.backendEndPoint}search/energyflow?postalCode=${data.postalCode}&houseNumber=${data.houseNumber}&houseNumberAddition=${data.houseNumberAddition}`
      } else {
        url = `${axiosDefaultConfig.backendEndPoint}search/energyflow?postalCode=${data.postalCode}&houseNumber=${data.houseNumber}`
      }
      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCorrespondenceAddress(ctx, data) {
      let url = ''
      if (data.houseNumberAddition) {
        url = `${axiosDefaultConfig.backendEndPoint}search/address?postalCode=${data.postalCode}&houseNumber=${data.houseNumber}&houseNumberAddition=${data.houseNumberAddition}`
      } else {
        url = `${axiosDefaultConfig.backendEndPoint}search/address?postalCode=${data.postalCode}&houseNumber=${data.houseNumber}`
      }
      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProducts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}products`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createOrders(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}orders`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    setRelation(ctx, relation) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}energyflow/relation`, relation)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getDraftCalculation(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}energyflow/productcalculations `, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createOrder(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}orders `, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    checkEmailDeployment(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}validate-email`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    clientNumberSearch(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}relations/clientnumbersearch`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchErpConnectionsForRelation(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}relations/${data.id}/erpconnections`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAWESExternalConnections(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}relations/${data.id}/externalconnections?includeEDSN=1`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSuppliers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}suppliers`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
