<template>
  <div style="height: inherit">
    <!-- Overlay -->
    <div class="body-content-overlay" />
    <section
      v-if="chargingPointProducts.length > 0"
      class="grid-view wishlist-items"
    >
      <b-card
        v-for="product in chargingPointProducts"
        :key="product.id"
        class="ecommerce-card"
        no-body
      >
        <div
          class="mt-4"
          style="text-align: center;"
        >
          <i
            class="fas fa-plug mt-2 mb-2 mx-1"
            style="font-size: 60px; color:#fb8500"
          />
        </div>

        <!-- category Details -->
        <b-card-body>
          <h6 class="item-name">
            <b-link
              class="text-body text-center"
              :to="{ name: 'apps-order-flow-checkout'}"
            >
              {{ product.name }}
            </b-link>
          </h6>
        </b-card-body>

        <!-- category Actions -->
        <div class="item-options text-center">
          <b-button
            variant="primary"
            tag="a"
            class="btn-cart"
            @click="setSelectedProduct(product)"
          >
            <span>
              <b-spinner
                v-if="spinner"
                small
                class="mr-1"
              />
              Selecteren
            </span>
          </b-button>
        </div>
      </b-card>
    </section>
    <b-row
      v-if="chargingPointProducts.length < 1"
    >
      <b-col
        cols="12"
      >
        <b-alert
          variant="primary"
          :show="true"
        >
          <div class="alert-body">
            Op basis van uw informatie kunnen wij op dit moment geen propositie aanbieden.
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12 text-left"
      >
        <b-card>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            @click="stepBack"
          >
            <span>terug</span>
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BLink, BButton, BCol, BRow, BSpinner,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard, BCardBody, BLink, BButton, BCol, BRow, BSpinner,

  },
  props: {
    chargingPointProducts: {
      type: Array,
      default: () => [],
    },
    spinner: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    setSelectedProduct(product) {
      this.$emit('set-select-charging-point-product', product)
    },
    stepBack() {
      this.$emit('back-step')
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
.ecommerce-application .ecommerce-card .item-name a {
  display: block !important;
  -webkit-line-clamp: initial !important;
  overflow: visible !important;
  text-overflow: initial !important;
}
</style>
