<template>
  <b-row>
    <b-modal
      id="modal-add-ean"
      ref="modalAddEan"
      hide-footer
      centered
      size="sm"
      :title="addEanData.type === 'e' ? 'Electriciteit EAN toevoegen' : 'Gas EAN toevoegen'"
      :no-close-on-backdrop="false"
    >
      <b-card-text>
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormAddEanObserver"
        >
          <b-form
            @submit.prevent="handleSubmit(addEAN)"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="addEanPostalCode"
                  rules="required|postalcode"
                >
                  <b-form-group
                    label="Postcode"
                    label-for="addEanPostalCode"
                  >
                    <b-form-input
                      id="addEanPostalCode"
                      v-model="addEanData.postalCode"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="addEanHouseNumber"
                  rules="required|integer"
                >
                  <b-form-group
                    label="Huisnummer"
                    label-for="addEanHouseNumber"
                  >
                    <b-form-input
                      id="addEanHouseNumber"
                      v-model="addEanData.houseNumber"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="addEanHouseNumberAddition"
                  rules=""
                >
                  <b-form-group
                    label="Huisnummertoevoeging"
                    label-for="addEanHouseNumberAddition"
                  >
                    <b-form-input
                      id="addEanHouseNumberAddition"
                      v-model="addEanData.houseNumberAddition"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12 text-right mt-1 mb-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  type="submit"
                  :disabled="showAddEANSpinner"
                >
                  <b-spinner
                    v-if="showAddEANSpinner"
                    small
                    class="mr-1"
                  />
                  <span>Toevoegen</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>
    <b-modal
      id="modal-estimate-usage"
      ref="modalEstimateUsage"
      cancel-variant="outline-secondary"
      ok-disabled
      cancel-title="Close"
      centered
      title="Jaarverbruik indicatie bepaler"
      :hide-footer="true"
      size="lg"
    >
      <b-row>
        <b-col
          v-if="localOrderData.isBusiness === 0"
          cols="12"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="border-radius: 10px;"
            class="mr-1 mt-2 mb-2"
            @click="setUsage(1)"
          >
            <i
              class="fas fa-male"
              style="font-size: 20px; color:#fb8500"
            /><br><br>
            1 persoon
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="border-radius: 10px;"
            class="mr-1 mt-2 mb-2"
            @click="setUsage(2)"
          >
            <i
              class="fas fa-male"
              style="font-size: 20px; color:#fb8500"
            /><i
              class="fas fa-male"
              style="font-size: 20px; color:#fb8500"
            /><br><br>
            2 persoon
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="border-radius: 10px;"
            class="mr-1 mt-2 mb-2"
            @click="setUsage(3)"
          >
            <i
              class="fas fa-male"
              style="font-size: 20px; color:#fb8500"
            /><i
              class="fas fa-male"
              style="font-size: 20px; color:#fb8500"
            /><i
              class="fas fa-male"
              style="font-size: 20px; color:#fb8500"
            /><br><br>
            3 persoon
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="border-radius: 10px;"
            class="mr-1 mt-2 mb-2"
            @click="setUsage(4)"
          >
            <i
              class="fas fa-male"
              style="font-size: 20px; color:#fb8500"
            /><i
              class="fas fa-male"
              style="font-size: 20px; color:#fb8500"
            /><i
              class="fas fa-male"
              style="font-size: 20px; color:#fb8500"
            /><i
              class="fas fa-male"
              style="font-size: 20px; color:#fb8500"
            /><br><br>
            4 persoon
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="border-radius: 10px;"
            class="mt-2 mb-2"
            @click="setUsage(5)"
          >
            <i
              class="fas fa-male"
              style="font-size: 20px; color:#fb8500"
            /><i
              class="fas fa-male"
              style="font-size: 20px; color:#fb8500"
            /><i
              class="fas fa-male"
              style="font-size: 20px; color:#fb8500"
            /><i
              class="fas fa-male"
              style="font-size: 20px; color:#fb8500"
            /><i
              class="fas fa-male"
              style="font-size: 20px; color:#fb8500"
            /><br><br>
            5 persoon
          </b-button>
        </b-col>
        <b-col
          v-if="localOrderData.isBusiness === 1"
          cols="12"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="border-radius: 10px;"
            class="mr-1 mt-2 mb-2"
            @click="setUsage(6)"
          >
            <i
              class="fas fa-home"
              style="font-size: 20px; color:#fb8500"
            /><br><br>
            ZZP
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="border-radius: 10px;"
            class="mr-1 mt-2 mb-2"
            @click="setUsage(7)"
          >
            <i
              class="fas fa-store-alt"
              style="font-size: 20px; color:#fb8500"
            /><br><br>
            Eenmanszaak
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="border-radius: 10px;"
            class="mr-1 mt-2 mb-2"
            @click="setUsage(8)"
          >
            <i
              class="fas fa-store"
              style="font-size: 20px; color:#fb8500"
            /><br><br>
            Winkel
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="border-radius: 10px;"
            class="mr-1 mt-2 mb-2"
            @click="setUsage(9)"
          >
            <i
              class="fas fa-building"
              style="font-size: 20px; color:#fb8500"
            /><br><br>
            kantoorpand
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="border-radius: 10px;"
            class="mt-2 mb-2"
            @click="setUsage(10)"
          >
            <i
              class="fas fa-industry"
              style="font-size: 20px; color:#fb8500"
            /><br><br>
            Industrieel
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-col cols="12">
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormSearchClientObserver"
      >
        <b-form
          @submit.prevent="handleSubmit(searchClient)"
        >
          <b-row class="match-height">
            <b-col
              cols="12"
            >
              <b-card title="Klant zoeken">
                <b-row>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="clientNumber"
                      rules="required"
                    >
                      <b-form-group
                        label="Klantnummer"
                        label-for="clientNumber"
                      >
                        <b-form-input
                          id="clientNumber"
                          v-model="searchClientData.clientNumber"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="supplierID"
                      rules="required"
                    >
                      <b-form-group
                        label="Label"
                        label-for="supplierID"
                        :state="getValidationState(validationContext)"
                      >
                        <v-select
                          v-model="searchClientData.supplierID"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="supplierOptions"
                          :clearable="false"
                          :reduce="val => val.id"
                          label="name"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                    class="text-left mt-2"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="secondary"
                      type="submit"
                      :disabled="showSearchClientSpinner"
                    >
                      <b-spinner
                        v-if="showSearchClientSpinner"
                        small
                        class="mr-1"
                      />
                      <span>Zoeken</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-col>
    <b-col
      cols="12"
      md="12"
    >
      <b-card>
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <b-row class="match-height">
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  ref="postalCodeCheck"
                  name="postalcode"
                  rules="required|postalcode"
                >
                  <b-form-group
                    label="Postcode"
                    label-for="postalcode"
                  >
                    <b-form-input
                      id="postalcode"
                      v-model="supplyAddress.postalCode"
                      v-debounce:500ms="enableAddressSearchButton"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <validation-provider
                  #default="validationContext"
                  ref="houseNumberCheck"
                  name="houseNumber"
                  rules="required|integer"
                >
                  <b-form-group
                    label="Huisnummer"
                    label-for="houseNumber"
                  >
                    <b-form-input
                      id="houseNumber"
                      v-model="supplyAddress.houseNumber"
                      v-debounce:500ms="enableAddressSearchButton"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <validation-provider
                  #default="validationContext"
                  name="houseNumberAddition"
                  rules=""
                >
                  <b-form-group
                    label="Huisnummertoevoeging"
                    label-for="houseNumberAddition"
                  >
                    <b-form-input
                      id="houseNumberAddition"
                      v-model="supplyAddress.houseNumberAddition"
                      v-debounce:500ms="enableAddressSearchButton"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="2"
                class="text-left mt-2"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="secondary"
                  type="button"
                  :disabled="!addressSearchButtonEnabled"
                  @click="fetchAddress"
                >
                  <span>Zoeken</span>
                </b-button>
              </b-col>

              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="street"
                  rules=""
                >
                  <b-form-group
                    label="Straat"
                    label-for="street"
                  >
                    <b-form-input
                      id="street"
                      v-model="supplyAddress.street"
                      :state="getValidationState(validationContext)"
                      :disabled="true"
                    />
                    <b-spinner
                      v-if="showAddressSpinner"
                      style="position:absolute; top:26px;right:20px"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="city"
                  rules=""
                >
                  <b-form-group
                    label="Plaats"
                    label-for="city"
                  >
                    <b-form-input
                      id="city"
                      v-model="supplyAddress.city"
                      :state="getValidationState(validationContext)"
                      :disabled="true"
                    />
                    <b-spinner
                      v-if="showAddressSpinner"
                      style="position:absolute; top:26px;right:20px"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="province"
                  rules=""
                >
                  <b-form-group
                    label="Provincie"
                    label-for="province"
                  >
                    <b-form-input
                      id="province"
                      v-model="supplyAddress.province"
                      :state="getValidationState(validationContext)"
                      :disabled="true"
                    />
                    <b-spinner
                      v-if="showAddressSpinner"
                      style="position:absolute; top:26px;right:20px"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="12"
                class="mb-1"
              >
                <validation-provider
                  #default="validationContext"
                  name="type"
                  rules="required"
                >
                  <label>Particulier / Zakelijk</label>
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      v-model="localOrderData.isBusiness"
                      name="type"
                      :value="0"
                      class="mt-0"
                      :state="getValidationState(validationContext)"
                    >
                      Particulier
                    </b-form-radio>
                    <b-form-radio
                      v-model="localOrderData.isBusiness"
                      name="type"
                      :value="1"
                      class="mt-0"
                      :state="getValidationState(validationContext)"
                    >
                      Zakelijk
                    </b-form-radio>
                  </div>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                v-if="showConnectionOptions"
                cols="12"
                class="mt-1"
              >
                <b-card
                  no-body
                >
                  <div class="mt-2 mr-2 mb-2">
                    <b-row>
                      <b-col
                        cols="12"
                        md="6"
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                      >
                        <h4>Electriciteit EAN</h4>
                      </b-col>
                      <b-col
                        class="d-flex align-items-center justify-content-end"
                        cols="12"
                        md="6"
                      >
                        <b-button
                          variant="primary"
                          @click="openAddEanModal('e')"
                        >
                          Extra aansluiting toevoegen
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>
                  <div
                    ref="formElectricityEans"
                    class="repeater-form"
                    :style="{height: trHeightElectricityEans}"
                  >
                    <b-row
                      class="p-0 m-0"
                    >
                      <b-col
                        v-if="getEansOrChargingPoint('EAN', 'ELECTRICITY').length < 1"
                        cols="12"
                        class="p-0 m-0"
                      >
                        <b-alert
                          v-height-fade.appear
                          :show="true"
                          variant="primary"
                        >
                          <div class="alert-body">
                            <feather-icon
                              icon="InfoIcon"
                              class="mr-50"
                            />
                            Er is op dit moment geen electriciteit ean toegevoegd.
                          </div>
                        </b-alert>
                      </b-col>
                      <b-col
                        v-if="getEansOrChargingPoint('EAN', 'ELECTRICITY').length > 0"
                        cols="12"
                        class="p-0 m-0"
                      >
                        <div
                          v-for="(item, index) in localOrderData.connections"
                          :key="`invoice-line-${index}`"
                          ref="rowElectricityEans"
                        >
                          <div
                            v-if="(item.type === 'EAN' || item.type === 'CHARGINGPOINT' && item.ean) && item.marketSegment === 'ELECTRICITY'"
                            class="d-flex border rounded"
                          >
                            <div
                              v-if="item.type === 'EAN' && item.marketSegment === 'ELECTRICITY'"
                              class="flex-grow-1 p-2"
                            >
                              <b-row>
                                <b-col
                                  cols="12"
                                  md="4"
                                >
                                  <label class="d-inline">EAN {{ item.usageType === 'SMALLCONSUMER' ? '(kleinverbruik)' : '' }}{{ item.usageType === 'LARGECONSUMER' ? '(Grootverbruik)' : '' }} {{ item.bagPurpose && item.usageType !== 'LARGECONSUMER' ? `- ${item.bagPurpose}` : '' }}</label>
                                  <validation-provider
                                    #default="validationContext"
                                    :name="`ElectricityEanItemEanNumber${index}`"
                                    rules="required"
                                  >
                                    <b-form-group
                                      label=""
                                      :label-for="`ElectricityEanItemEanNumber${index}`"
                                    >
                                      <b-form-input
                                        :id="`ElectricityEanItemEanNumber${index}`"
                                        v-model="item.ean"
                                        type="text"
                                        :state="getValidationState(validationContext)"
                                        disabled="disabled"
                                      />
                                    </b-form-group>
                                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                      {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                  </validation-provider>
                                </b-col>
                                <b-col
                                  v-if="item.usageType === 'SMALLCONSUMER'"
                                  cols="12"
                                  md="2"
                                >
                                  <label class="d-inline mb-0">Aansluiting</label>
                                  <v-select
                                    :id="`ElectricityEanItemCapacityCode${index}`"
                                    v-model="item.capacityCode"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="capacityCodeElectricityOptions"
                                    label="code"
                                    :reduce="val => val.value"
                                    :clearable="false"
                                    class="per-page-selector d-inline-block"
                                    style="width: 100%"
                                  />
                                </b-col>
                                <b-col
                                  v-if="item.usageType === 'LARGECONSUMER'"
                                  cols="12"
                                  md="2"
                                >
                                  <label class="d-inline">Aansluitcapaciteit (kW)</label>
                                  <validation-provider
                                    #default="validationContext"
                                    :name="`ElectricityEanItemEanCapacityCode${index}`"
                                    rules="required"
                                  >
                                    <b-form-group
                                      label=""
                                      :label-for="`ElectricityEanItemEanCapacityCode${index}`"
                                    >
                                      <b-form-input
                                        :id="`ElectricityEanItemEanCapacityCode${index}`"
                                        v-model="item.capacityCode"
                                        type="text"
                                        :state="getValidationState(validationContext)"
                                      />
                                    </b-form-group>
                                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                      {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                  </validation-provider>
                                </b-col>
                                <b-col
                                  cols="12"
                                  md="2"
                                >
                                  <label class="d-inline">Postcode</label>
                                  <validation-provider
                                    #default="validationContext"
                                    :name="`ElectricityEanItemPostalCode${index}`"
                                    rules="required"
                                  >
                                    <b-form-group
                                      label=""
                                      :label-for="`ElectricityEanItemPostalCode${index}`"
                                    >
                                      <b-form-input
                                        :id="`ElectricityEanItemPostalCode${index}`"
                                        v-model="item.postalCode"
                                        type="text"
                                        :state="getValidationState(validationContext)"
                                        disabled="disabled"
                                      />
                                    </b-form-group>
                                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                      {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                  </validation-provider>
                                </b-col>
                                <b-col
                                  cols="12"
                                  md="2"
                                >
                                  <label class="d-inline">Huisnummer</label>
                                  <validation-provider
                                    #default="validationContext"
                                    :name="`ElectricityEanItemHouseNumber${index}`"
                                    rules="required"
                                  >
                                    <b-form-group
                                      label=""
                                      :label-for="`ElectricityEanItemHouseNumber${index}`"
                                    >
                                      <b-form-input
                                        :id="`ElectricityEanItemHouseNumber${index}`"
                                        v-model="item.houseNumber"
                                        type="text"
                                        :state="getValidationState(validationContext)"
                                        disabled="disabled"
                                      />
                                    </b-form-group>
                                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                      {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                  </validation-provider>
                                </b-col>
                                <b-col
                                  cols="12"
                                  md=""
                                >
                                  <label class="d-inline">Toevoeging</label>
                                  <validation-provider
                                    #default="validationContext"
                                    :name="`ElectricityEanItemHouseNumberAddition${index}`"
                                    rules=""
                                  >
                                    <b-form-group
                                      label=""
                                      :label-for="`ElectricityEanItemHouseNumberAddition${index}`"
                                    >
                                      <b-form-input
                                        :id="`ElectricityEanItemHouseNumberAddition${index}`"
                                        v-model="item.houseNumberAddition"
                                        type="text"
                                        :state="getValidationState(validationContext)"
                                        disabled="disabled"
                                      />
                                    </b-form-group>
                                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                      {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                  </validation-provider>
                                </b-col>
                              </b-row>
                              <b-row class="mb-1">
                                <b-col
                                  cols="12"
                                  md="3"
                                >
                                  <validation-provider
                                    #default="validationContext"
                                    :name="`ElectricityEanItemResidenceFunction${index}`"
                                    :rules="``"
                                  >
                                    <label>Woon-/verblijfsfunctie</label>
                                    <div class="demo-inline-spacing">
                                      <b-form-radio
                                        v-model="item.isResidenceFunction"
                                        :name="`ElectricityEanItemResidenceFunction${index}`"
                                        :value="true"
                                        :checked="true"
                                        class="mt-0"
                                        :state="getValidationState(validationContext)"
                                      >
                                        Ja
                                      </b-form-radio>
                                      <b-form-radio
                                        v-model="item.residenceFunction"
                                        :name="`ElectricityEanItemResidenceFunction${index}`"
                                        :value="false"
                                        class="mt-0"
                                        :state="getValidationState(validationContext)"
                                      >
                                        Nee
                                      </b-form-radio>
                                    </div>
                                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                      {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                  </validation-provider>
                                </b-col>
                                <b-col
                                  cols="12"
                                  md="3"
                                >
                                  <validation-provider
                                    #default="validationContext"
                                    :name="`ElectricityEanItemSwitchType${index}`"
                                    :rules="``"
                                  >
                                    <label>Inhuizing</label>
                                    <div class="demo-inline-spacing">
                                      <b-form-radio
                                        v-model="item.switchType"
                                        :name="`ElectricityEanItemSwitchType${index}`"
                                        value="SWITCH"
                                        class="mt-0"
                                        :state="getValidationState(validationContext)"
                                      >
                                        Nee
                                      </b-form-radio>
                                      <b-form-radio
                                        v-model="item.switchType"
                                        :name="`ElectricityEanItemSwitchType${index}`"
                                        value="MOVEIN"
                                        class="mt-0"
                                        :state="getValidationState(validationContext)"
                                      >
                                        Ja
                                      </b-form-radio>
                                    </div>
                                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                      {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                  </validation-provider>
                                </b-col>
                                <b-col
                                  cols="12"
                                  md="3"
                                >
                                  <validation-provider
                                    #default="validationContext"
                                    :name="`ElectricityEanItemMeterType${index}`"
                                    :rules="``"
                                  >
                                    <label>Dubbele meter</label>
                                    <div class="demo-inline-spacing">
                                      <b-form-radio
                                        v-model="item.meterType"
                                        :name="`ElectricityEanItemMeterType${index}`"
                                        value="SINGLE"
                                        :checked="true"
                                        class="mt-0"
                                        :state="getValidationState(validationContext)"
                                      >
                                        Nee
                                      </b-form-radio>
                                      <b-form-radio
                                        v-model="item.meterType"
                                        :name="`ElectricityEanItemMeterType${index}`"
                                        value="DOUBLE"
                                        class="mt-0"
                                        :state="getValidationState(validationContext)"
                                      >
                                        Ja
                                      </b-form-radio>
                                    </div>
                                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                      {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                  </validation-provider>
                                </b-col>
                                <b-col
                                  cols="12"
                                  md="3"
                                >
                                  <validation-provider
                                    #default="validationContext"
                                    :name="`ElectricityEanItemHasReturn${index}`"
                                    :rules="``"
                                  >
                                    <label>Teruglevering</label>
                                    <div class="demo-inline-spacing">
                                      <b-form-radio
                                        v-model="item.hasReturn"
                                        :name="`ElectricityEanItemHasReturn${index}`"
                                        :value="false"
                                        :checked="true"
                                        class="mt-0"
                                        :state="getValidationState(validationContext)"
                                      >
                                        Nee
                                      </b-form-radio>
                                      <b-form-radio
                                        v-model="item.hasReturn"
                                        :name="`ElectricityEanItemHasReturn${index}`"
                                        :value="true"
                                        class="mt-0"
                                        :state="getValidationState(validationContext)"
                                      >
                                        Ja
                                      </b-form-radio>
                                    </div>
                                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                      {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                  </validation-provider>
                                </b-col>
                                <!--                                  <b-col-->
                                <!--                                    cols="12"-->
                                <!--                                    md="3"-->
                                <!--                                  >-->
                                <!--                                    <validation-provider-->
                                <!--                                      #default="validationContext"-->
                                <!--                                      :name="`ElectricityEanIsChargingPoint${index}`"-->
                                <!--                                    >-->
                                <!--                                      <label>Is een laadpaal</label>-->
                                <!--                                      <div class="demo-inline-spacing">-->
                                <!--                                        <b-form-radio-->
                                <!--                                          v-model="item.isChargingPoint"-->
                                <!--                                          :name="`ElectricityEanIsChargingPoint${index}`"-->
                                <!--                                          :value="false"-->
                                <!--                                          class="mt-0"-->
                                <!--                                          :state="getValidationState(validationContext)"-->
                                <!--                                          :disabled="getEansOrChargingPoint('EAN', 'ELECTRICITY').length < 2"-->
                                <!--                                        >-->
                                <!--                                          Nee-->
                                <!--                                        </b-form-radio>-->
                                <!--                                        <b-form-radio-->
                                <!--                                          v-model="item.isChargingPoint"-->
                                <!--                                          :name="`ElectricityEanIsChargingPoint${index}`"-->
                                <!--                                          :value="true"-->
                                <!--                                          class="mt-0"-->
                                <!--                                          :state="getValidationState(validationContext)"-->
                                <!--                                          :disabled="getEansOrChargingPoint('EAN', 'ELECTRICITY').length < 2"-->
                                <!--                                        >-->
                                <!--                                          Ja-->
                                <!--                                        </b-form-radio>-->
                                <!--                                      </div>-->
                                <!--                                      <b-form-invalid-feedback :state="getValidationState(validationContext)">-->
                                <!--                                        {{ validationContext.errors[0] }}-->
                                <!--                                      </b-form-invalid-feedback>-->
                                <!--                                    </validation-provider>-->
                                <!--                                  </b-col>-->
                              </b-row>
                              <b-row>
                                <b-col
                                  v-if="item.meterType === 'SINGLE'"
                                  cols="12"
                                  md="3"
                                >
                                  <label class="d-inline">Elektriciteit</label>
                                  <validation-provider
                                    #default="validationContext"
                                    :name="`ElectricityEanItemUsageSingel${index}`"
                                    :rules="`required`"
                                  >
                                    <b-form-group
                                      label=""
                                      :label-for="`ElectricityEanItemUsageSingel${index}`"
                                    >
                                      <b-form-input
                                        :id="`ElectricityEanItemUsageSingel${index}`"
                                        v-model="item.usageElectricitySingle"
                                        :state="getValidationState(validationContext)"
                                        placeholder=""
                                        type="number"
                                      />
                                    </b-form-group>
                                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                      {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                  </validation-provider>
                                </b-col>
                                <b-col
                                  v-if="item.meterType === 'DOUBLE'"
                                  cols="12"
                                  md="3"
                                >
                                  <label class="d-inline">Elektriciteit normaal</label>
                                  <validation-provider
                                    #default="validationContext"
                                    :name="`ElectricityEanItemUsageNormal${index}`"
                                    :rules="`required`"
                                  >
                                    <b-form-group
                                      label=""
                                      :label-for="`ElectricityEanItemUsageNormal${index}`"
                                    >
                                      <b-form-input
                                        :id="`ElectricityEanItemUsageNormal${index}`"
                                        v-model="item.usageElectricityHigh"
                                        :state="getValidationState(validationContext)"
                                        placeholder=""
                                        type="number"
                                      />
                                    </b-form-group>
                                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                      {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                  </validation-provider>
                                </b-col>
                                <b-col
                                  v-if="item.meterType === 'DOUBLE'"
                                  cols="12"
                                  md="3"
                                >
                                  <label class="d-inline">Elektriciteit Dal</label>
                                  <validation-provider
                                    #default="validationContext"
                                    :name="`ElectricityEanItemUsageDal${index}`"
                                    :rules="`required`"
                                  >
                                    <b-form-group
                                      label=""
                                      :label-for="`ElectricityEanItemUsageDal${index}`"
                                    >
                                      <b-form-input
                                        :id="`ElectricityEanItemUsageDal${index}`"
                                        v-model="item.usageElectricityLow"
                                        :state="getValidationState(validationContext)"
                                        placeholder=""
                                        type="number"
                                      />
                                    </b-form-group>
                                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                      {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                  </validation-provider>
                                </b-col>
                                <b-col
                                  v-if="item.meterType === 'SINGLE' && item.hasReturn"
                                  cols="12"
                                  md="3"
                                >
                                  <label class="d-inline">Elektriciteit teruggeleverd</label>
                                  <validation-provider
                                    #default="validationContext"
                                    :name="`ElectricityEanItemReturnElectricitySingle${index}`"
                                    :rules="`required`"
                                  >
                                    <b-form-group
                                      label=""
                                      :label-for="`ElectricityEanItemReturnElectricitySingle${index}`"
                                    >
                                      <b-form-input
                                        :id="`ElectricityEanItemReturnElectricitySingle${index}`"
                                        v-model="item.returnElectricitySingle"
                                        :state="getValidationState(validationContext)"
                                        placeholder=""
                                        type="number"
                                      />
                                    </b-form-group>
                                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                      {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                  </validation-provider>
                                </b-col>
                                <b-col
                                  v-if="item.meterType === 'DOUBLE' && item.hasReturn"
                                  cols="12"
                                  md="3"
                                >
                                  <label class="d-inline">Elektriciteit teruggeleverd normaal</label>
                                  <validation-provider
                                    #default="validationContext"
                                    :name="`ElectricityEanItemReturnElectricityHigh${index}`"
                                    :rules="`required`"
                                  >
                                    <b-form-group
                                      label=""
                                      :label-for="`ElectricityEanItemReturnElectricityHigh${index}`"
                                    >
                                      <b-form-input
                                        :id="`ElectricityEanItemReturnElectricityHigh${index}`"
                                        v-model="item.returnElectricityHigh"
                                        :state="getValidationState(validationContext)"
                                        placeholder=""
                                        type="number"
                                      />
                                    </b-form-group>
                                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                      {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                  </validation-provider>
                                </b-col>
                                <b-col
                                  v-if="item.meterType === 'DOUBLE' && item.hasReturn"
                                  cols="12"
                                  md="3"
                                >
                                  <label class="d-inline">Elektriciteit teruggeleverd dal</label>
                                  <validation-provider
                                    #default="validationContext"
                                    :name="`ElectricityEanItemReturnElectricityLow${index}`"
                                    :rules="`required`"
                                  >
                                    <b-form-group
                                      label=""
                                      :label-for="`ElectricityEanItemReturnElectricityLow${index}`"
                                    >
                                      <b-form-input
                                        :id="`ElectricityEanItemReturnElectricityLow${index}`"
                                        v-model="item.returnElectricityLow"
                                        :state="getValidationState(validationContext)"
                                        placeholder=""
                                        type="number"
                                      />
                                    </b-form-group>
                                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                      {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                  </validation-provider>
                                </b-col>
                                <!--                                  <b-col-->
                                <!--                                    v-if="item.isChargingPoint"-->
                                <!--                                    cols="12"-->
                                <!--                                    md="6"-->
                                <!--                                  >-->
                                <!--                                    <label class="d-inline mb-0">Laadpaal product </label>-->
                                <!--                                    <v-select-->
                                <!--                                      :id="`ElectricityEanItemCapacityCode${index}`"-->
                                <!--                                      v-model="item.chargingPointProductID"-->
                                <!--                                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
                                <!--                                      :options="chargingPointProducts"-->
                                <!--                                      label="name"-->
                                <!--                                      :reduce="val => val.id"-->
                                <!--                                      :clearable="false"-->
                                <!--                                      class="per-page-selector d-inline-block"-->
                                <!--                                      style="width: 100%"-->
                                <!--                                    />-->
                                <!--                                  </b-col>-->
                              </b-row>
                            </div>
                            <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                              <feather-icon
                                :id="`delete-electricity-action-row-${item.id}`"
                                size="16"
                                icon="XIcon"
                                class="cursor-pointer"
                                @click="removeElectricityEanLine(index)"
                              />
                              <b-tooltip
                                :target="`delete-electricity-action-row-${item.id}`"
                                placement="left"
                              >
                                <p class="mb-0">
                                  Verwijderen
                                </p>
                              </b-tooltip>
                              <feather-icon
                                :id="`estimate-electricity-action-row-${item.id}`"
                                size="16"
                                icon="TargetIcon"
                                class="cursor-pointer"
                                @click="estimateUsage(index,'ELECTRICITY')"
                              />
                              <b-tooltip
                                :target="`estimate-electricity-action-row-${item.id}`"
                                placement="left"
                              >
                                <p class="mb-0">
                                  Jaarverbruik indicatie bepaler
                                </p>
                              </b-tooltip>
                            </div>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </b-card>
              </b-col>
              <b-col
                v-if="showConnectionOptions"
                cols="12"
              >
                <b-card
                  no-body
                >
                  <div class="mt-2 mr-2 mb-2">
                    <b-row>
                      <b-col
                        cols="12"
                        md="6"
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                      >
                        <h4>Gas EAN</h4>
                      </b-col>
                      <b-col
                        class="d-flex align-items-center justify-content-end"
                        cols="12"
                        md="6"
                      >
                        <b-button
                          variant="primary"
                          @click="openAddEanModal('g')"
                        >
                          Extra aansluiting toevoegen
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>
                  <div
                    ref="formGasEans"
                    class="repeater-form"
                    :style="{height: trHeightGasEans}"
                  >
                    <b-row
                      class="p-0 m-0"
                    >
                      <b-col
                        v-if="getEansOrChargingPoint('EAN', 'GAS').length < 1"
                        cols="12"
                        class="p-0 m-0"
                      >
                        <b-alert
                          v-height-fade.appear
                          :show="true"
                          variant="primary"
                        >
                          <div class="alert-body">
                            <feather-icon
                              icon="InfoIcon"
                              class="mr-50"
                            />
                            Er is op dit moment geen gas ean toegevoegd.
                          </div>
                        </b-alert>
                      </b-col>
                      <b-col
                        v-if="getEansOrChargingPoint('EAN', 'GAS').length > 0"
                        cols="12"
                        class="p-0 m-0"
                      >
                        <div
                          v-for="(item, index) in localOrderData.connections"
                          :key="`invoice-line-${index}`"
                          ref="rowGasEans"
                        >
                          <div
                            v-if="item.type === 'EAN' && item.marketSegment === 'GAS'"
                            class="d-flex border rounded"
                          >
                            <b-row
                              class="flex-grow-1 p-2"
                            >
                              <b-col
                                cols="12"
                                md="4"
                              >
                                <label class="d-inline">EAN {{ item.usageType === 'SMALLCONSUMER' ? '(kleinverbruik)' : '' }}{{ item.usageType === 'LARGECONSUMER' ? '(Grootverbruik)' : '' }} {{ item.bagPurpose && item.usageType !== 'LARGECONSUMER' ? '- ' + item.bagPurpose : '' }}</label>
                                <validation-provider
                                  #default="validationContext"
                                  :name="`GasEanItemEanNumber${index}`"
                                  rules="required"
                                >
                                  <b-form-group
                                    label=""
                                    :label-for="`GasEanItemEanNumber${index}`"
                                  >
                                    <b-form-input
                                      :id="`GasEanItemEanNumber${index}`"
                                      v-model="item.ean"
                                      type="text"
                                      :state="getValidationState(validationContext)"
                                      disabled="disabled"
                                    />
                                  </b-form-group>
                                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                    {{ validationContext.errors[0] }}
                                  </b-form-invalid-feedback>
                                </validation-provider>
                              </b-col>
                              <b-col
                                v-if="item.usageType === 'LARGECONSUMER'"
                                cols="12"
                                md="2"
                              >
                                <label class="d-inline">Aansluitcapaciteit (kW)</label>
                                <validation-provider
                                  #default="validationContext"
                                  :name="`GasEanItemCapacityCode${index}`"
                                  rules="required"
                                >
                                  <b-form-group
                                    label=""
                                    :label-for="`GasEanItemCapacityCode${index}`"
                                  >
                                    <b-form-input
                                      :id="`GasEanItemCapacityCode${index}`"
                                      v-model="item.capacityCode"
                                      type="text"
                                      :state="getValidationState(validationContext)"
                                    />
                                  </b-form-group>
                                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                    {{ validationContext.errors[0] }}
                                  </b-form-invalid-feedback>
                                </validation-provider>
                              </b-col>
                              <b-col
                                v-if="item.usageType === 'SMALLCONSUMER'"
                                cols="12"
                                md="2"
                              >
                                <label class="d-inline mb-0">Aansluiting </label>
                                <v-select
                                  :id="`GasEanItemCapacityCode${index}`"
                                  v-model="item.capacityCode"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  :options="capacityCodeGasOptions"
                                  label="code"
                                  :reduce="val => val.value"
                                  :clearable="false"
                                  class="per-page-selector d-inline-block"
                                  style="width: 100%"
                                />
                              </b-col>
                              <b-col
                                cols="12"
                                md="2"
                              >
                                <label class="d-inline">Postcode</label>
                                <validation-provider
                                  #default="validationContext"
                                  :name="`GasEanItemPostalCode${index}`"
                                  rules="required"
                                >
                                  <b-form-group
                                    label=""
                                    :label-for="`GasEanItemPostalCode${index}`"
                                  >
                                    <b-form-input
                                      :id="`GasEanItemPostalCode${index}`"
                                      v-model="item.postalCode"
                                      type="text"
                                      :state="getValidationState(validationContext)"
                                      disabled="disabled"
                                    />
                                  </b-form-group>
                                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                    {{ validationContext.errors[0] }}
                                  </b-form-invalid-feedback>
                                </validation-provider>
                              </b-col>
                              <b-col
                                cols="12"
                                md="2"
                              >
                                <label class="d-inline">Huisnummer</label>
                                <validation-provider
                                  #default="validationContext"
                                  :name="`GasEanItemHouseNumber${index}`"
                                  rules="required"
                                >
                                  <b-form-group
                                    label=""
                                    :label-for="`GasEanItemHouseNumber${index}`"
                                  >
                                    <b-form-input
                                      :id="`GasEanItemHouseNumber${index}`"
                                      v-model="item.houseNumber"
                                      type="text"
                                      :state="getValidationState(validationContext)"
                                      disabled="disabled"
                                    />
                                  </b-form-group>
                                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                    {{ validationContext.errors[0] }}
                                  </b-form-invalid-feedback>
                                </validation-provider>
                              </b-col>
                              <b-col
                                cols="12"
                                md=""
                              >
                                <label class="d-inline">Toevoeging</label>
                                <validation-provider
                                  #default="validationContext"
                                  :name="`GasEanItemHouseNumberAddition${index}`"
                                  rules=""
                                >
                                  <b-form-group
                                    label=""
                                    :label-for="`GasEanItemHouseNumberAddition${index}`"
                                  >
                                    <b-form-input
                                      :id="`GasEanItemHouseNumberAddition${index}`"
                                      v-model="item.houseNumberAddition"
                                      type="text"
                                      :state="getValidationState(validationContext)"
                                      disabled="disabled"
                                    />
                                  </b-form-group>
                                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                    {{ validationContext.errors[0] }}
                                  </b-form-invalid-feedback>
                                </validation-provider>
                              </b-col>
                              <b-col
                                cols="12"
                                md="3"
                              >
                                <validation-provider
                                  #default="validationContext"
                                  :name="`GasEanItemSwitchType${index}`"
                                  :rules="``"
                                >
                                  <label>Inhuizing</label>
                                  <div class="demo-inline-spacing">
                                    <b-form-radio
                                      v-model="item.switchType"
                                      :name="`GasEanItemSwitchType${index}`"
                                      value="SWITCH"
                                      class="mt-0"
                                      :state="getValidationState(validationContext)"
                                    >
                                      Nee
                                    </b-form-radio>
                                    <b-form-radio
                                      v-model="item.switchType"
                                      :name="`GasEanItemSwitchType${index}`"
                                      value="MOVEIN"
                                      class="mt-0"
                                      :state="getValidationState(validationContext)"
                                    >
                                      Ja
                                    </b-form-radio>
                                  </div>
                                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                    {{ validationContext.errors[0] }}
                                  </b-form-invalid-feedback>
                                </validation-provider>
                              </b-col>
                              <b-col
                                cols="12"
                                md="3"
                              >
                                <label class="d-inline">Gas</label>
                                <validation-provider
                                  #default="validationContext"
                                  :name="`GasEanItemUsage${index}`"
                                  :rules="`required`"
                                >
                                  <b-form-group
                                    label=""
                                    :label-for="`GasEanItemUsage${index}`"
                                  >
                                    <b-form-input
                                      :id="`GasEanItemUsage${index}`"
                                      v-model="item.usageGas"
                                      :state="getValidationState(validationContext)"
                                      placeholder=""
                                      type="number"
                                    />
                                  </b-form-group>
                                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                    {{ validationContext.errors[0] }}
                                  </b-form-invalid-feedback>
                                </validation-provider>
                              </b-col>
                            </b-row>
                            <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                              <feather-icon
                                :id="`delete-gas-action-row-${item.id}`"
                                size="16"
                                icon="XIcon"
                                class="cursor-pointer"
                                @click="removeGasEanLine(index)"
                              />
                              <b-tooltip
                                :target="`delete-gas-action-row-${item.id}`"
                                placement="left"
                              >
                                <p class="mb-0">
                                  Verwijderen
                                </p>
                              </b-tooltip>
                              <feather-icon
                                :id="`estimate-gas-action-row-${item.id}`"
                                size="16"
                                icon="TargetIcon"
                                class="cursor-pointer"
                                @click="estimateUsage(index, 'GAS')"
                              />
                              <b-tooltip
                                :target="`estimate-gas-action-row-${item.id}`"
                                placement="left"
                              >
                                <p class="mb-0">
                                  Jaarverbruik indicatie bepaler
                                </p>
                              </b-tooltip>
                            </div>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </b-card>
              </b-col>
              <b-col
                v-if="showConnectionOptions && chargingPointEnabled === 'true'"
                cols="12"
              >
                <div
                  ref="formChargingStation"
                  class="repeater-form"
                  :style="{height: trHeightChargingStation}"
                >
                  <b-card
                    no-body
                  >
                    <div class="mt-2 mr-2 mb-2">
                      <b-row>
                        <b-col
                          cols="12"
                          md="6"
                          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                        >
                          <h4>Laadpaal</h4>
                        </b-col>
                        <b-col
                          class="d-flex align-items-center justify-content-end"
                          cols="12"
                          md="6"
                        >
                          <b-button
                            variant="primary"
                            @click="addNewChargingStationLine"
                          >
                            Laadpaal toevoegen
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>
                    <div
                      ref="formChargingStation"
                      class="repeater-form"
                      :style="{height: trHeightChargingStation}"
                    >
                      <b-row
                        class="p-0 m-0"
                      >
                        <b-col
                          v-if="getEansOrChargingPoint('CHARGINGPOINT', 'ELECTRICITY').length < 1 && chargingPointEnabled === 'true'"
                          cols="12"
                          class="p-0 m-0"
                        >
                          <b-alert
                            v-height-fade.appear
                            :show="true"
                            variant="primary"
                          >
                            <div class="alert-body">
                              <feather-icon
                                icon="InfoIcon"
                                class="mr-50"
                              />
                              Er is op dit moment geen Laadpaal (zonder eigen EAN) toegevoegd.
                            </div>
                          </b-alert>
                        </b-col>
                        <b-col
                          v-if="getEansOrChargingPoint('CHARGINGPOINT', 'ELECTRICITY').length > 0 && chargingPointEnabled === 'true'"
                          cols="12"
                          class="p-0 m-0"
                        >
                          <div
                            v-for="(item, index) in localOrderData.connections"
                            :key="`invoice-line-${index}`"
                            ref="rowChargingStation"
                          >
                            <div
                              v-if="item.type === 'CHARGINGPOINT' && item.marketSegment === 'ELECTRICITY' && !item.ean"
                              class="d-flex border rounded"
                            >
                              <template>
                                <b-row
                                  class="flex-grow-1 p-2"
                                >
                                  <!-- Single Item Form Headers -->
                                  <b-col
                                    cols="12"
                                    md="4"
                                  >
                                    <label class="d-inline">ID</label>
                                    <validation-provider
                                      #default="validationContext"
                                      :name="`chargingPointID${index}`"
                                      rules="required"
                                    >
                                      <b-form-group
                                        label=""
                                        :label-for="`chargingPointID${index}`"
                                      >
                                        <b-form-input
                                          :id="`chargingPointID${index}`"
                                          v-model="item.chargingPointID"
                                          type="text"
                                          :state="getValidationState(validationContext)"
                                        />
                                      </b-form-group>
                                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                        {{ validationContext.errors[0] }}
                                      </b-form-invalid-feedback>
                                    </validation-provider>
                                  </b-col>
                                  <b-col
                                    cols="12"
                                    md="4"
                                  >
                                    <validation-provider
                                      #default="validationContext"
                                      :name="`chargingPointCarType{index}`"
                                      rules="required"
                                    >
                                      <b-form-group
                                        label="Type auto?"
                                        :label-for="`chargingPointCarType{index}`"
                                        :state="getValidationState(validationContext)"
                                      >
                                        <v-select
                                          :id="`chargingPointPeriodType${index}`"
                                          v-model="item.chargingPointCarType"
                                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                          :options="chargingPointCarTypeOptions"
                                          :clearable="true"
                                          :reduce="val => val.value"
                                          label="label"
                                        />
                                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                          {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                      </b-form-group>
                                    </validation-provider>
                                  </b-col>
                                  <b-col
                                    cols="12"
                                    md="4"
                                  >
                                    <validation-provider
                                      #default="validationContext"
                                      :name="`chargingPointPeriodType{index}`"
                                      rules="required"
                                    >
                                      <b-form-group
                                        label="Wanneer is de laadpaal geplaatst"
                                        :label-for="`chargingPointPeriodType{index}`"
                                        :state="getValidationState(validationContext)"
                                      >
                                        <v-select
                                          :id="`chargingPointPeriodType${index}`"
                                          v-model="item.chargingPointPeriodType"
                                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                          :options="chargingPointPeriodOptions"
                                          :clearable="true"
                                          :reduce="val => val.value"
                                          label="label"
                                        />
                                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                          {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                      </b-form-group>
                                    </validation-provider>
                                  </b-col>
                                </b-row>
                                <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                                  <feather-icon
                                    :id="`delete-action-row-${item.id}`"
                                    size="16"
                                    icon="XIcon"
                                    class="cursor-pointer"
                                    @click="removeChargingStationLine(index)"
                                  />
                                  <b-tooltip
                                    :target="`delete-action-row-${item.id}`"
                                    placement="left"
                                  >
                                    <p class="mb-0">
                                      Verwijderen
                                    </p>
                                  </b-tooltip>
                                </div>
                              </template>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card>
                </div>
              </b-col>
              <b-col
                cols="12"
              >
                <b-row
                  class="mt-2 p-0"
                >
                  <b-col
                    cols="6 text-left"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="secondary"
                      @click="stepBack"
                    >
                      <span>terug</span>
                    </b-button>
                  </b-col>
                  <b-col
                    cols="6 text-right"
                  >
                    <b-button
                      variant="primary"
                      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                      type="submit"
                      :disabled="!showConnectionOptions || showStepSupplyAddressSpinner"
                      show-step-supply-address-spinner
                    >
                      <b-spinner
                        v-if="showStepSupplyAddressSpinner"
                        small
                        class="mr-1"
                      />
                      <span>Volgende</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BRow,
  BFormRadio, BAlert, BCardText, BSpinner, BTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  alphaNum,
  email,
  required,
  date,
  regex,
  integer,
  length,
  postalcode,
} from '@core/utils/validations/validations'
import store from '@/store'
import { getDirective } from 'vue-debounce'
import { heightTransition } from '@core/mixins/ui/transition'
import vSelect from 'vue-select'
import { heightFade } from '@core/directives/animations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useOrderFlow from '@/views/pages/order-flow/useOrderFlow'

export default {
  directives: {
    Ripple,
    debounce: getDirective(),
    'height-fade': heightFade,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BButton,
    BFormRadio,
    BAlert,
    BCardText,
    BSpinner,
    BTooltip,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [heightTransition],
  props: {
    orderData: {
      type: Object,
      default: () => {},
    },
    showStepSupplyAddressSpinner: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      chargingPointEnabled: false,
      localOrderData: {
        productID: null,
        chargingPointProductID: null,
        relationID: null,
        connections: [],
        flowID: null,
        signatureSVG: null,
        isBusiness: 0,
      },
      supplyAddress: {
        postalCode: null,
        houseNumber: null,
        houseNumberAddition: null,
        street: null,
        city: null,
        province: null,
        type: 0,
      },
      blankConnectionData: {
        type: 'EAN', // EAN or CHARGINGPOINT
        switchType: 'SWITCH',
        marketSegment: null, // ELECTRICITY or GAS
        usageType: null, // SMALLCONSUMER or LARGECONSUMER or ARTICLE
        ean: null,
        chargingPointID: null,
        postalCode: null,
        houseNumber: 0,
        houseNumberAddition: null,
        street: null,
        city: null,
        gridOperator: null,
        gridOperatorEAN: null,
        gridAreaEAN: null,
        isSecondaryAllocation: false,
        isResidenceFunction: true,
        isChargingPoint: false,
        bagPurpose: null,
        profile: null,
        meterType: 'SINGLE', // SINGLE or DOUBLE
        capacityCode: null,
        usageElectricityHigh: null,
        usageElectricityLow: null,
        usageElectricitySingle: null,
        usageGas: null,
        hasReturn: false,
        returnElectricityHigh: null,
        returnElectricityLow: null,
        returnElectricitySingle: null,
        chargingPointIDSegment1: null,
        chargingPointIDSegment2: null,
        chargingPointIDSegment3: null,
        currentSupplier: '',
        chargingPointCarType: null,
        chargingPointPeriodType: null,
      },
      addEanData: {
        postalCode: null,
        houseNumber: null,
        houseNumberAddition: null,
        type: null,
      },
      businessOptions: [
        { item: false, name: 'Particulier' },
        { item: true, name: 'Zakelijk' },
      ],
      typeOptions: [
        { item: 0, name: 'Elektriciteit & Gas' },
        { item: 1, name: 'Elektriciteit' },
        { item: 2, name: 'Gas' },
      ],
      usageOptions: [
        { item: 0, name: 'Ik weet mijn verbruik' },
        { item: 1, name: 'Ik weet mijn verbruik niet' },
      ],
      returnOptions: [
        { item: 0, name: 'Nee' },
        { item: 1, name: 'Ja' },
      ],
      usageEstimationOptions: {
        1: {
          gas: 1100,
          electricity: 1800,
        },
        2: {
          gas: 1200,
          electricity: 2700,
        },
        3: {
          gas: 1500,
          electricity: 3500,
        },
        4: {
          gas: 1700,
          electricity: 4500,
        },
        5: {
          gas: 1900,
          electricity: 5000,
        },
        6: {
          gas: 1700,
          electricity: 3500,
        },
        7: {
          gas: 2000,
          electricity: 6500,
        },
        8: {
          gas: 4000,
          electricity: 16000,
        },
        9: {
          gas: 10000,
          electricity: 50000,
        },
        10: {
          gas: 20000,
          electricity: 70000,
        },
      },
      capacityCodeElectricityOptions: [
        { value: '10211', code: '3x25A' },
        { value: '10311', code: '3x35A' },
        { value: '10411', code: '3x50A' },
        { value: '10511', code: '3x63A' },
        { value: '10611', code: '3x80A' },
      ],
      capacityCodeGasOptions: [
        { value: '20111', code: 't/m G6' },
        { value: '20411', code: 'G10' },
        { value: '20511', code: 'G16' },
        { value: '20611', code: 'G25' },
      ],
      trHeightElectricityEans: 0,
      trHeightChargingStation: 0,
      trHeightGasEans: 0,
      estimator: {
        meterType: false,
        type: null,
        index: null,
      },
      searchClientData: {
        supplierID: null,
        clientNumber: null,
      },
      supplierOptions: [],
      showConnectionOptions: false,
      showAddEANSpinner: false,
      showAddressSpinner: false,
      showSearchClientSpinner: false,
      addressSearchButtonEnabled: false,
      envLabel: null,
    }
  },
  watch: {
    orderData: {
      deep: true,
      handler(data) {
        this.localOrderData = data
      },
    },
  },
  mounted() {
    this.chargingPointEnabled = process.env.VUE_APP_CHARGINGPOINT
    this.envLabel = process.env.VUE_APP_LABEL
  },
  beforeMount() {
    this.fetchSuppliers()
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    enableAddressSearchButton() {
      const somethingChanged = []

      if (!this.supplyAddress.postalCode || !this.supplyAddress.houseNumber) {
        this.addressSearchButtonEnabled = false
      }

      if (this.supplyAddress.postalCode && this.supplyAddress.houseNumber) {
        somethingChanged.push(true)
      }

      if (somethingChanged.includes(true)) {
        this.addressSearchButtonEnabled = true
      } else {
        this.addressSearchButtonEnabled = false
      }
    },
    fetchSuppliers() {
      store.dispatch('apps-order-flow-checkout/fetchSuppliers', {
        sortBy: 'name',
        sortDesc: false,
      })
        .then(response => {
          this.supplierOptions = response.data.items
        })
        .catch(() => {
          this.suppliersOptions = []
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error bij het ophalen van de labels',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    searchClient() {
      this.localOrderData.connections = []
      this.showSearchClientSpinner = true
      store.dispatch('apps-order-flow-checkout/clientNumberSearch', this.searchClientData)
        .then(response => {
          if (response.data.success) {
            this.$emit('set-relation', { relation: response.data.relation, next: false })

            if (!response.data.isExternal) {
              store.dispatch('apps-order-flow-checkout/fetchErpConnectionsForRelation', { id: response.data.relation.id })
                .then(connections => {
                  connections.data.forEach(item => {
                    if (item.marketSegment === 'ELECTRICITY') {
                      item.isResidenceFunction = true
                      if (item.usageType === 'SMALLCONSUMER') {
                        item.capacityCode = '10211'
                      }
                      item.meterType = 'SINGLE'
                      item.switchType = 'SWITCH'
                      item.hasReturn = item.returnElectricityHigh > 0 || item.returnElectricityLow > 0 || item.returnElectricitySingle > 0
                      this.localOrderData.connections.push(item)
                    }
                    if (item.marketSegment === 'GAS') {
                      if (item.usageType === 'SMALLCONSUMER') {
                        item.capacityCode = '20111'
                      }
                      item.switchType = 'SWITCH'
                      this.localOrderData.connections.push(item)
                    }
                  })

                  this.supplyAddress.postalCode = response.data.relation.postalCode.toUpperCase().split(' ').join('')
                  this.supplyAddress.houseNumber = response.data.relation.houseNumber
                  this.supplyAddress.houseNumberAddition = response.data.relation.houseNumberAddition
                  this.supplyAddress.street = response.data.relation.street
                  this.supplyAddress.city = response.data.relation.city
                  this.supplyAddress.province = response.data.relation.province
                  this.showConnectionOptions = true
                  this.initTrHeight()
                  window.addEventListener('resize', this.initTrHeight)
                  this.showSearchClientSpinner = false
                })
                .catch(() => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Error bij het ophalen van de aansluitingen.',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                })
            } else if (response.data.isExternal) {
              store.dispatch('apps-order-flow-checkout/fetchAWESExternalConnections', { id: response.data.relation.id })
                .then(connections => {
                  if (connections.data && connections.data.ELECTRICITY) {
                    connections.data.ELECTRICITY.forEach(item => {
                      item.isResidenceFunction = true
                      item.chargingPointCarType = null
                      item.chargingPointPeriodType = null
                      if (item.usageType === 'SMALLCONSUMER') {
                        item.capacityCode = '10211'
                      }
                      item.meterType = 'SINGLE'
                      item.switchType = 'SWITCH'
                      item.hasReturn = item.returnElectricityHigh > 0 || item.returnElectricityLow > 0 || item.returnElectricitySingle > 0
                      this.localOrderData.connections.push(item)
                    })
                  }

                  if (connections.data && connections.data.GAS) {
                    connections.data.GAS.forEach(item => {
                      if (item.usageType === 'SMALLCONSUMER') {
                        item.capacityCode = '20111'
                      }
                      item.switchType = 'SWITCH'
                      this.localOrderData.connections.push(item)
                    })
                  }

                  this.supplyAddress.postalCode = response.data.relation.postalCode.toUpperCase().split(' ').join('')
                  this.supplyAddress.houseNumber = response.data.relation.houseNumber
                  this.supplyAddress.houseNumberAddition = response.data.relation.houseNumberAddition
                  this.supplyAddress.street = response.data.relation.street
                  this.supplyAddress.city = response.data.relation.city
                  this.supplyAddress.province = response.data.relation.province
                  this.showConnectionOptions = true
                  this.initTrHeight()
                  window.addEventListener('resize', this.initTrHeight)
                  this.showSearchClientSpinner = false
                })
                .catch(() => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Error bij het ophalen van de aansluitingen.',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                })
            } else {
              this.showSearchClientSpinner = false
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Klant zoeken',
                  icon: 'AlertTriangleIcon',
                  variant: 'warning',
                  text: 'Er is geen klant gevonden voor het opgegeven klantnummer.',
                },
              })
            }
          } else {
            this.showSearchClientSpinner = false
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Klant zoeken',
                icon: 'AlertTriangleIcon',
                variant: 'warning',
                text: 'Er is geen klant gevonden voor het opgegeven klantnummer.',
              },
            })
          }
        })
        .catch(() => {
          this.showSearchClientSpinner = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error bij het ophalen van de data',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    openAddEanModal(type) {
      this.addEanData.type = type
      this.$refs.modalAddEan.show()
    },
    addEAN() {
      this.showAddEANSpinner = true
      store.dispatch('apps-order-flow-checkout/fetchAddress', this.addEanData)
        .then(response => {
          if (this.addEanData.type === 'e') {
            response.data.ean.ELECTRICITY.forEach(item => {
              if (!this.localOrderData.connections.some(obj => obj.marketSegment === 'ELECTRICITY')
                  || this.localOrderData.connections.some(obj => obj.marketSegment === 'ELECTRICITY' && obj.ean !== item.ean)) {
                if (item.usageType === 'SMALLCONSUMER') {
                  item.capacityCode = '10211'
                }
                item.switchType = 'SWITCH'
                item.isResidenceFunction = true
                item.hasReturn = false
                item.isChargingPoint = false
                this.localOrderData.connections.push(item)
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'EAN is al toegevoegd',
                    icon: 'AlertTriangleIcon',
                    variant: 'warning',
                  },
                })
              }
            })
          }

          if (this.addEanData.type === 'g') {
            response.data.ean.GAS.forEach(item => {
              if (!this.localOrderData.connections.some(obj => obj.marketSegment === 'GAS')
                  || this.localOrderData.connections.some(obj => obj.marketSegment === 'GAS' && obj.ean !== item.ean)) {
                if (item.usageType === 'SMALLCONSUMER') {
                  item.capacityCode = '20111'
                }
                item.meterType = 'SINGLE'
                item.switchType = 'SWITCH'
                item.usageGas = null
                this.localOrderData.connections.push(item)
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'EAN is al toegevoegd',
                    icon: 'AlertTriangleIcon',
                    variant: 'warning',
                  },
                })
              }
            })
          }

          this.initTrHeight()
          window.addEventListener('resize', this.initTrHeight)
          this.showAddEANSpinner = false
          this.addEanData.postalCode = null
          this.addEanData.houseNumber = null
          this.addEanData.houseNumberAddition = null
          this.$refs.refFormAddEanObserver.reset()
          this.$refs.modalAddEan.hide()
        })
        .catch(() => {
          this.showAddressSpinner = false
        })
    },
    getEansOrChargingPoint(type, marketSegment) {
      return this.localOrderData.connections.filter(connection => connection.marketSegment === marketSegment && connection.type === type)
    },
    async allowFetchAddress() {
      let allowAddressSearch = false
      await this.validateField('postalCodeCheck').then(async a => {
        if (a.valid) {
          await this.validateField('houseNumberCheck').then(b => {
            if (b.valid) {
              allowAddressSearch = true
            }
          })
        }
      })
      return allowAddressSearch
    },
    validateField(field) {
      const provider = this.$refs[field]
      // Validate the field
      return provider.validate()
    },
    async fetchAddress() {
      let allowed = false
      allowed = await this.allowFetchAddress()
      if (allowed) {
        this.showAddressSpinner = true
        this.showConnectionOptions = false
        this.supplyAddress.street = null
        this.supplyAddress.city = null
        this.supplyAddress.province = null
        store.dispatch('apps-order-flow-checkout/fetchAddress', this.supplyAddress)
          .then(response => {
            this.searchClientData.supplierID = null
            this.searchClientData.clientNumber = null
            this.$refs.refFormSearchClientObserver.reset()
            this.localOrderData.connections = []
            response.data.ean.ELECTRICITY.forEach(item => {
              if (item.usageType === 'SMALLCONSUMER') {
                item.capacityCode = '10211'
              }
              // item.meterType = 'SINGLE'
              item.switchType = 'SWITCH'
              item.isResidenceFunction = true
              // item.usageElectricityHigh = null
              // item.usageElectricityLow = null
              // item.usageElectricitySingle = null
              // item.returnElectricityHigh = null
              // item.returnElectricityLow = null
              // item.returnElectricitySingle = null
              item.hasReturn = false
              item.isChargingPoint = false
              this.localOrderData.connections.push(item)
            })

            response.data.ean.GAS.forEach(item => {
              if (item.usageType === 'SMALLCONSUMER') {
                item.capacityCode = '20111'
              }
              item.meterType = 'SINGLE'
              item.switchType = 'SWITCH'
              item.usageGas = null
              this.localOrderData.connections.push(item)
            })
            this.supplyAddress.street = response.data.address.street
            this.supplyAddress.city = response.data.address.city
            this.supplyAddress.province = response.data.address.province
            this.showConnectionOptions = true
            this.showAddressSpinner = false
            this.initTrHeight()
            window.addEventListener('resize', this.initTrHeight)
          })
          .catch(() => {
            this.showAddressSpinner = false
          })
      }
    },
    addNewChargingStationLine() {
      this.$refs.formChargingStation.style.overflow = 'hidden'
      const newEan = this.blankConnectionData
      newEan.type = 'CHARGINGPOINT'
      newEan.marketSegment = 'ELECTRICITY'
      this.localOrderData.connections.push(newEan)

      this.$nextTick(() => {
        this.trHeightChargingStation = this.getHeight(this.$refs.rowChargingStation[0].offsetHeight)
        setTimeout(() => {
          this.$refs.formChargingStation.style.overflow = null
        }, 350)
      })
    },
    removeChargingStationLine(index) {
      this.localOrderData.connections.splice(index, 1)
      this.trHeightChargingStation = this.getHeight(this.$refs.rowChargingStation[0].offsetHeight)
    },
    addNewElectricityEanLine() {
      this.$refs.formElectricityEans.style.overflow = 'hidden'
      const newEan = this.blankConnectionData
      if (newEan.usageType === 'SMALLCONSUMER') {
        newEan.capacityCode = '10211'
      }
      newEan.marketSegment = 'ELECTRICITY'
      this.localOrderData.connections.push(newEan)

      this.$nextTick(() => {
        this.trHeightElectricityEans = this.getHeight(this.$refs.rowElectricityEans[0].offsetHeight)
        setTimeout(() => {
          this.$refs.formElectricityEans.style.overflow = null
        }, 350)
      })
    },
    removeElectricityEanLine(index) {
      this.localOrderData.connections.splice(index, 1)
      this.trHeightElectricityEans = this.getHeight(this.$refs.rowElectricityEans[0].offsetHeight)
    },
    addNewGasEanLine() {
      this.$refs.formGasEans.style.overflow = 'hidden'
      const newEan = this.blankConnectionData
      if (newEan.usageType === 'SMALLCONSUMER') {
        newEan.capacityCode = '20111'
      }
      newEan.marketSegment = 'GAS'
      this.localOrderData.connections.push(newEan)

      this.$nextTick(() => {
        this.trHeightGasEans = this.getHeight(this.$refs.rowGasEans[0].offsetHeight)
        setTimeout(() => {
          this.$refs.formGasEans.style.overflow = null
        }, 350)
      })
    },
    removeGasEanLine(index) {
      this.localOrderData.connections.splice(index, 1)
      this.trHeightGasEans = this.getHeight(this.$refs.rowGasEans[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        if (this.$refs.formElectricityEans) {
          this.trHeightElectricityEans = this.getHeight(this.$refs.formElectricityEans.scrollHeight)
        }
        if (this.$refs.formGasEans) {
          this.trHeightGasEans = this.getHeight(this.$refs.formGasEans.scrollHeight)
        }
        if (this.$refs.formChargingStation) {
          this.trHeightChargingStation = this.getHeight(this.$refs.formChargingStation.scrollHeight)
        }
      })
    },
    getHeight(val) {
      if (val === null) {
        this.trHeight = 'auto'
      } else {
        this.trHeight = `${Number(val)}px`
      }
    },
    estimateUsage(index, type) {
      if (type === 'ELECTRICITY') {
        this.estimator.meterType = this.localOrderData.connections[index].meterType
      }
      this.estimator.type = type
      this.estimator.index = index
      this.$refs.modalEstimateUsage.show()
    },
    setUsage(selection) {
      // gas and electricity
      if (this.estimator.type === 'ELECTRICITY') {
        this.localOrderData.connections[this.estimator.index].usageElectricitySingle = this.usageEstimationOptions[selection].electricity
        this.localOrderData.connections[this.estimator.index].usageElectricityHigh = (this.usageEstimationOptions[selection].electricity / 2)
        this.localOrderData.connections[this.estimator.index].usageElectricityLow = (this.usageEstimationOptions[selection].electricity / 2)
      }

      // gas only
      if (this.estimator.type === 'GAS') {
        this.localOrderData.connections[this.estimator.index].usageGas = this.usageEstimationOptions[selection].gas
      }

      this.$refs.modalEstimateUsage.hide()
    },
    async onSubmit() {
      if (this.localOrderData.connections.some(con => con.usageType === 'SMALLCONSUMER') && this.localOrderData.connections.some(con => con.usageType === 'LARGECONSUMER')) {
        this.$swal({
          icon: 'error',
          title: 'Niet mogelijk',
          text: 'Je kunt geen kleinverbruik en grootverbruik in één order combineren. Indien dit voorkomt maak dan twee aparte orders aan. ',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      } else {
        await this.$emit('set-selected-supply-address', this.supplyAddress)
        await this.$emit('set-order-data', this.localOrderData)
      }
    },
    stepBack() {
      this.$emit('back-step')
    },
  },
  setup() {
    const {
      chargingPointPeriodOptions,
      chargingPointCarTypeOptions,
    } = useOrderFlow()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      alphaNum,
      email,
      required,
      date,
      regex,
      integer,
      length,
      postalcode,
      chargingPointPeriodOptions,
      chargingPointCarTypeOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

[dir] .input-group-text {
  border: 1px solid #d8d6de !important;
}

.input-group-text {
  height: 38px !important;
}

.usageSelected {
  background-color: rgba(255, 100, 0, 0.2);
}

[dir] .input-group-text {
  border: 1px solid #d8d6de !important;
}

.input-group-text {
  height: 38px !important;
}
</style>
