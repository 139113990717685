<template>
  <div
    style="height: inherit"
  >
    <b-modal
      id="modal-tariff"
      ref="modalTariff"
      hide-footer
      centered
      size="lg"
      title="Tarieven"
      :no-close-on-backdrop="false"
    >
      <b-card-text>
        <b-row v-if="selectedProduct">
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Elektriciteit enkeltarief"
              label-for="tariffSingle"
            >
              <b-form-input
                id="tariffSingle"
                v-model="selectedProduct.lastTariff.tariffSingle"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Elektriciteit normaaltarief"
              label-for="tariffHigh"
            >
              <b-form-input
                id="tariffHigh"
                v-model="selectedProduct.lastTariff.tariffHigh"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Elektriciteit daltarief"
              label-for="tariffLow"
            >
              <b-form-input
                id="tariffLow"
                v-model="selectedProduct.lastTariff.tariffLow"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Vaste kosten elektriciteit"
              label-for="tariffFixedEnergy"
            >
              <b-form-input
                id="tariffFixedEnergy"
                v-model="selectedProduct.lastTariff.tariffFixedEnergy"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="selectedProduct">
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Teruglevering elektriciteit enkeltarief"
              label-for="tariffReturnSingle"
            >
              <b-form-input
                id="tariffReturnSingle"
                v-model="selectedProduct.lastTariff.tariffReturnSingle"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Teruglevering elektriciteit normaaltarief"
              label-for="tariffReturnHigh"
            >
              <b-form-input
                id="tariffReturnHigh"
                v-model="selectedProduct.lastTariff.tariffReturnHigh"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Teruglevering elektriciteit daltarief"
              label-for="tariffReturnLow"
            >
              <b-form-input
                id="tariffReturnLow"
                v-model="selectedProduct.lastTariff.tariffReturnLow"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="selectedProduct">
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Leveringstarief gas (G1)"
              label-for="tariffGas1"
            >
              <b-form-input
                id="tariffGas1"
                v-model="selectedProduct.lastTariff.tariffGas1"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Leveringstarief gas (G2)"
              label-for="tariffGas2"
            >
              <b-form-input
                id="tariffGas2"
                v-model="selectedProduct.lastTariff.tariffGas2"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Vaste kosten gas"
              label-for="tariffFixedGas"
            >
              <b-form-input
                id="tariffFixedGas"
                v-model="selectedProduct.lastTariff.tariffFixedGas"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>
    <div>
      <section
        v-if="localDraftCalculation.length > 0"
        class="grid-view wishlist-items"
      >
        <b-card
          v-for="item in localDraftCalculation"
          :key="item.product.id"
          class="ecommerce-card"
          no-body
        >
          <div
            class="mt-4"
            style="text-align: center;"
          >
            <b-img
              :src="item.product.supplier.logoFileURL"
              alt=""
              width="100px"
            />
          </div>
          <b-card-body>
            <h6 class="item-name">
              <b-link
                class="text-body text-center"
              >
                {{ item.product.name }}
              </b-link>
            </h6>
          </b-card-body>

          <b-card-body class="mb-0 pb-0">
            <b-row>
              <b-col
                cols="12"
                v-html="item.product.uspText"
              />
            </b-row>
          </b-card-body>

          <b-card-body class="mt-0 pt-0">
            <h4
              v-if="item.product.isBusiness === 0 || item.product.isSohoProposition === 1"
              class="item-name text-center"
            >
              <b-link
                @click="openTariffModal(item.product)"
              >
                <feather-icon
                  class="cursor-pointer text-center"
                  icon="InfoIcon"
                  size="16"
                /> <span v-if="!item.calculationSummary" style="font-size: 14px;">Tariefinformatie</span>
              </b-link>

              <span v-if="item.calculationSummary">&euro; {{ item.calculationSummary.totalAmount }} <small>per maand</small></span>
            </h4>
            <h4
              v-if="item.product.isBusiness === 1 && item.product.isSohoProposition === 0"
              class="item-name text-center"
            >
              <b-link
                @click="openTariffModal(item.product)"
              >
                <feather-icon
                  class="cursor-pointer text-center"
                  icon="InfoIcon"
                  size="16"
                /> <span v-if="!item.calculationSummary" style="font-size: 14px;">Tariefinformatie</span>
              </b-link>
              <span v-if="item.calculationSummary">&euro; {{ item.calculationSummary.totalSubAmount }} <small>per maand</small></span>
            </h4>
            <div
              v-if="item.calculationSummary"
              class="text-center"
            >
              <small class="text-center text-muted">{{ item.product.isBusiness === 1 && item.product.isSohoProposition === 0 ? 'exclusief BTW' : 'inclusief BTW' }}</small>
            </div>
          </b-card-body>

          <!-- category Actions -->
          <div class="item-options text-center">
            <b-button
              variant="primary"
              tag="a"
              class="btn-cart"
              @click="setSelectedProduct(item.product)"
            >
              <span>Selecteren</span>
            </b-button>
          </div>
        </b-card>
      </section>
    </div>
    <b-row
      v-if="localDraftCalculation.length < 1"
    >
      <b-col
        cols="12"
      >
        <b-alert
          variant="primary"
          :show="true"
        >
          <div class="alert-body">
            Op basis van uw informatie kunnen wij op dit moment geen propositie aanbieden.
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12 text-left"
      >
        <b-card>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            @click="stepBack"
          >
            <span>terug</span>
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BLink, BButton, BCol, BRow, BImg, BAlert, BFormInput, BFormGroup, BCardText, BModal,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import { heightFade } from '@core/directives/animations'

export default {
  directives: {
    Ripple,
    'height-fade': heightFade,
  },
  components: {
    BCard,
    BCardBody,
    BLink,
    BButton,
    BCol,
    BRow,
    BImg,
    BAlert,
    BFormInput,
    BFormGroup,
    BCardText,
    BModal,
  },
  props: {
    draftCalculation: {
      type: Array,
      require: true,
      default: () => [],
    },
  },
  data() {
    return {
      localDraftCalculation: [],
      selectedProduct: null,
    }
  },
  watch: {
    draftCalculation: {
      deep: true,
      async handler(data) {
        this.localDraftCalculation = data
      },
    },
  },
  methods: {
    openTariffModal(data) {
      this.selectedProduct = data
      this.$refs.modalTariff.show()
    },
    setSelectedProduct(product) {
      this.$emit('set-select-product', product)
    },
    stepBack() {
      this.$emit('back-step')
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-ecommerce.scss";
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
.ecommerce-application .ecommerce-card .item-name a {
  display: block !important;
  -webkit-line-clamp: initial !important;
  overflow: visible !important;
  text-overflow: initial !important;
}

ul{
  padding-inline-start: 22px !important;
}
</style>
