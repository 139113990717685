<template>
  <div style="height: inherit">
    <!-- Overlay -->
    <div class="body-content-overlay" />
    <!-- Prodcuts -->
    <section class="grid-view wishlist-items">
      <b-card
        v-for="flow in flows"
        :key="flow.id"
        class="ecommerce-card"
        no-body
        @click="setSelectedCategory(flow)"
      >
        <div
          class="mt-4"
          style="text-align: center;"
        >
          <i :class="flow.icon" style="font-size: 60px; color:#fb8500"></i>
        </div>
        <b-card-body
        >
          <h6 class="item-name">
            <b-link
              class="text-body text-center"
            >
              {{ flow.name }}
            </b-link>
          </h6>
        </b-card-body>
        <div class="item-options text-center">
          <b-button
            variant="primary"
            tag="a"
            class="btn-cart"
          >
            <span>Selecteren</span>
          </b-button>
        </div>
      </b-card>
    </section>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BLink, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import errorAndSuccesDefaultMessage from '@core/app-config/errorAndSuccesDefaultMessage'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardBody,
    BLink,
    BButton,
  },
  data() {
    return {
      flows: [],
      showOverlay: false,
    }
  },
  beforeMount() {
    this.fetchFlows()
  },
  methods: {
    setSelectedCategory(flow) {
      this.$emit('set-select-flow', flow)
    },
    fetchFlows() {
      this.showOverlay = true
      store
        .dispatch('apps-order-flow-checkout/fetchFlows')
        .then(response => {
          this.showOverlay = false
          this.flows = response.data.items
        })
        .catch(() => {
          this.showOverlay = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ophalen flows',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: `${errorAndSuccesDefaultMessage.fetchDataError}`,
            },
          })
        })
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>
