<template>
  <div>
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <b-row class="match-height">
          <b-col
            cols="12"
          >
            <b-card title="Correspondentieadres">
              <b-row>
                <b-col
                  cols="12"
                  md="5"
                >
                  <validation-provider
                    #default="validationContext"
                    name="postalCode"
                    rules="required|postalcode"
                  >
                    <b-form-group
                      label="Postcode"
                      label-for="postalCode"
                    >
                      <b-form-input
                        id="postalCode"
                        v-model="relation.postalCode"
                        v-debounce:500ms="getAddress"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    name="houseNumber"
                    rules="required|integer"
                  >
                    <b-form-group
                      label="Huisnummer"
                      label-for="houseNumber"
                    >
                      <b-form-input
                        id="houseNumber"
                        v-model="relation.houseNumber"
                        v-debounce:500ms="getAddress"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="houseNumberAddition"
                    rules=""
                  >
                    <b-form-group
                      label="Huisnummertoevoeging"
                      label-for="houseNumberAddition"
                    >
                      <b-form-input
                        id="houseNumberAddition"
                        v-model="relation.houseNumberAddition"
                        v-debounce:500ms="getAddress"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="street"
                    rules=""
                  >
                    <b-form-group
                      label="Straat"
                      label-for="street"
                    >
                      <b-form-input
                        id="street"
                        v-model="relation.street"
                        :state="getValidationState(validationContext)"
                        :disabled="true"
                      />
                      <b-spinner
                        v-if="showAddressSpinner"
                        style="position:absolute; top:26px;right:20px"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="city"
                    rules=""
                  >
                    <b-form-group
                      label="Plaats"
                      label-for="city"
                    >
                      <b-form-input
                        id="city"
                        v-model="relation.city"
                        :state="getValidationState(validationContext)"
                        :disabled="true"
                      />
                      <b-spinner
                        v-if="showAddressSpinner"
                        style="position:absolute; top:26px;right:20px"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-card>
            <b-card title="Contactgegevens">
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  class="mb-1"
                >
                  <validation-provider
                    #default="validationContext"
                    name="gender"
                    rules="required"
                  >
                    <label>Geslacht</label>
                    <div class="demo-inline-spacing">
                      <b-form-radio
                        v-model="relation.gender"
                        name="gender"
                        value="m"
                        class="mt-0"
                        :state="getValidationState(validationContext)"
                      >
                        Man
                      </b-form-radio>
                      <b-form-radio
                        v-model="relation.gender"
                        name="gender"
                        value="f"
                        class="mt-0"
                        :state="getValidationState(validationContext)"
                      >
                        Vrouw
                      </b-form-radio>
                    </div>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="firstName"
                    rules="required"
                  >
                    <b-form-group
                      label="Voornaam"
                      label-for="firstName"
                    >
                      <b-form-input
                        id="firstName"
                        v-model="relation.firstName"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <b-form-group
                    label="Tussenvoegsel"
                    label-for="middleName"
                  >
                    <b-form-input
                      id="middleName"
                      v-model="relation.middleName"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="5"
                >
                  <validation-provider
                    #default="validationContext"
                    name="lastName"
                    rules="required"
                  >
                    <b-form-group
                      label="Achternaam"
                      label-for="lastName"
                    >
                      <b-form-input
                        id="lastname"
                        v-model="relation.lastName"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                >
                  <validation-provider
                    #default="validationContext"
                    name="birthDate"
                    rules=""
                  >
                    <b-form-group
                      label="Geboortedatum"
                      label-for="birthDate"
                      :state="getValidationState(validationContext)"
                    >
                      <flat-pickr
                        id="birthDate"
                        v-model="relation.birthDate"
                        :config="birthDateConfig"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="phoneNumber"
                    rules="required|integer|min:10"
                  >
                    <b-form-group
                      label="Telefoonnummer"
                      label-for="phoneNumber"
                    >
                      <b-form-input
                        id="phoneNumber"
                        v-model="relation.phoneNumber"
                        type="text"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="mobileNumber"
                    rules="required|integer|min:10"
                  >
                    <b-form-group
                      label="Mobiel nummer"
                      label-for="mobileNumber"
                    >
                      <b-form-input
                        id="mobileNumber"
                        v-model="relation.mobileNumber"
                        type="text"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="email"
                    rules="required|email"
                  >
                    <b-form-group
                      label="E-mailadres"
                      label-for="email"
                    >
                      <b-form-input
                        id="email"
                        v-model="relation.email"
                        type="email"
                        :state="getValidationState(validationContext) && (emailValid && !isFirstEmailCheck)"
                        @focusout="checkEmail"
                      />
                      <b-spinner
                        v-if="showEmailCheckSpinner"
                        style="position:absolute; top:26px;right:20px"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)||(emailValid && !isFirstEmailCheck)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-card>
            <b-card
              v-if="isBusiness === 1"
              title="Bedrijfsgegevens"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Zoek in de KvK register"
                    label-for="company"
                  >
                    <vue-autosuggest
                      ref="autocomplete"
                      v-model="query"
                      :suggestions="suggestions"
                      :input-props="inputProps"
                      :get-suggestion-value="getSuggestionValue"
                      @selected="onSelected($event.item)"
                      @input="fetchCocResults"
                    >
                      <template slot-scope="{suggestion}">
                        <b-row>
                          <b-col
                            cols="12"
                          >
                            <span class="my-suggestion-item">
                              {{ `${suggestion.item.companyName} - ${suggestion.item.cocNumber}` }}
                            </span>
                          </b-col>
                          <b-col
                            cols="12"
                          >
                            <span class="my-suggestion-item">
                              {{ `${suggestion.item.street} ${suggestion.item.houseNumber}${suggestion.item.houseNumberAddition}, ${suggestion.item.postalCode} ${suggestion.item.city}` }}
                            </span>
                          </b-col>
                        </b-row>
                      </template>
                    </vue-autosuggest>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Bedrijfsnaam"
                    rules="required"
                  >
                    <b-form-group
                      label="Bedrijfsnaam"
                      label-for="companyName"
                    >
                      <b-form-input
                        id="companyName"
                        v-model="relation.companyName"
                        :state="getValidationState(validationContext)"
                        :disabled="true"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="companyCocNumber"
                    rules="required"
                  >
                    <b-form-group
                      label="KVK-nummer"
                      label-for="companyCocNumber"
                    >
                      <b-form-input
                        id="companyCocNumber"
                        v-model="relation.companyCocNumber"
                        :state="getValidationState(validationContext)"
                        :disabled="true"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-card>
            <b-card title="Betaalgegevens">
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="bankAccountNumber"
                    rules="required|iban"
                  >
                    <b-form-group
                      label="IBAN"
                      label-for="bankAccountNumber"
                    >
                      <b-form-input
                        v-model="relation.bankAccountNumber"
                        name="bankAccountNumber"
                        :state="getValidationState(validationContext)"
                        trim
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="debtorName"
                    rules="required"
                  >
                    <b-form-group
                      label="Tenaamstelling"
                      label-for="ibanAscription"
                    >
                      <b-form-input
                        id="debtorName"
                        v-model="relation.debtorName"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="paymentMethod"
                    rules="required"
                  >
                    <b-form-group
                      label="Betaalwijze"
                      label-for="paymentMethod"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        v-model="relation.paymentMethod"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="paymentMethodOptions"
                        :clearable="false"
                        input-id="paymentMethod"
                        :reduce="val => val.value"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-card>
            <!--          <b-card title="Huidige situatie">-->
            <!--            <b-row>-->
            <!--              <b-col-->
            <!--                cols="12"-->
            <!--                md="12"-->
            <!--              >-->
            <!--                <validation-provider-->
            <!--                  #default="validationContext"-->
            <!--                  name="endDateContract"-->
            <!--                  rules="required"-->
            <!--                >-->
            <!--                  <b-form-group-->
            <!--                    label="Einddatum huidig contract"-->
            <!--                    label-for="endDateContract"-->
            <!--                    :state="getValidationState(validationContext)"-->
            <!--                  >-->
            <!--                    <flat-pickr-->
            <!--                      id="endateContract"-->
            <!--                      v-model="relation.endDateContract"-->
            <!--                      :config="endDateContractConfig"-->
            <!--                      :state="getValidationState(validationContext)"-->
            <!--                    >-->
            <!--                    </flat-pickr>-->
            <!--                    <b-form-invalid-feedback :state="getValidationState(validationContext)">-->
            <!--                      {{ validationContext.errors[0] }}-->
            <!--                    </b-form-invalid-feedback>-->
            <!--                  </b-form-group>-->
            <!--                </validation-provider>-->
            <!--              </b-col>-->
            <!--              <b-col-->
            <!--                cols="12"-->
            <!--                md="12"-->
            <!--              >-->
            <!--                <validation-provider-->
            <!--                  #default="validationContext"-->
            <!--                  name="currentSupplier"-->
            <!--                  rules="required"-->
            <!--                >-->
            <!--                  <b-form-group-->
            <!--                    label="Huidige leverancier"-->
            <!--                    label-for="currentSupplier"-->
            <!--                    :state="getValidationState(validationContext)"-->
            <!--                  >-->
            <!--                    <v-select-->
            <!--                      v-model="relation.currentSupplier"-->
            <!--                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
            <!--                      :options="supplierOptions"-->
            <!--                      :clearable="false"-->
            <!--                      input-id="currentSupplier"-->
            <!--                    />-->
            <!--                    <b-form-invalid-feedback :state="getValidationState(validationContext)">-->
            <!--                      {{ validationContext.errors[0] }}-->
            <!--                    </b-form-invalid-feedback>-->
            <!--                  </b-form-group>-->
            <!--                </validation-provider>-->
            <!--              </b-col>-->
            <!--            </b-row>-->
            <!--          </b-card>-->
          </b-col>
          <b-col
            cols="12"
          >
            <b-card>
              <b-row>
                <b-col
                  cols="6 text-left"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="secondary"
                    @click="stepBack"
                  >
                    <span>terug</span>
                  </b-button>
                </b-col>
                <b-col
                  cols="6 text-right"
                >
                  <b-button
                    variant="primary"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    type="submit"
                  >
                    <span>Volgende</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback, BFormRadio,
  BRow, BSpinner,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  alphaNum,
  email,
  required,
  date,
  regex,
  integer,
  postalcode,
  iban,
  min,
} from '@core/utils/validations/validations'
import { getFullName, todayMinYears } from '@core/utils/utils'
import { getDirective } from 'vue-debounce'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueAutosuggest } from 'vue-autosuggest'

export default {
  directives: {
    Ripple,
    debounce: getDirective(),
  },
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BButton,
    BFormRadio,
    flatPickr,
    BSpinner,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    VueAutosuggest,
  },
  props: {
    selectedSupplyAddress: {
      type: Object,
      default: () => {},
    },
    selectedRelation: {
      type: Object,
      default: () => {},
    },
    productId: {
      type: Number,
      default: () => null,
    },
    isBusiness: {
      type: Number,
      default: () => null,
    },
  },
  data() {
    return {
      relation: {
        type: this.getBusinessType(this.isBusiness),
        gender: 'm', // m of f
        firstName: null,
        middleName: null,
        lastName: null,
        birthDate: null,
        phoneNumber: null,
        mobileNumber: null,
        email: null,
        companyName: null,
        companyCocNumber: null,
        street: null,
        houseNumber: null,
        houseNumberAddition: null,
        postalCode: null,
        city: null,
        paymentMethod: null,
        debtorName: null,
        bankAccountType: 'IBAN',
        bankAccountNumber: null,
      },
      clientSearchData: {
        productID: null,
        clientNumber: null,
      },
      birthDateConfig: {
        maxDate: todayMinYears(18),
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
        altInput: true,
        allowInput: true,
      },
      limit: 10,
      query: '',
      results: [],
      timeout: null,
      debounceMilliseconds: 250,
      suggestions: [],
      inputProps: {
        id: 'autosuggest__input_ajax',
        class: 'form-control',
        placeholder: 'Zoeken...',
      },
      showAddressSpinner: false,
      paymentMethodOptions: [
        { label: 'Automatische incasso', value: 'AUTOMATICCOLLECTION' },
      ],
      emailValid: false,
      showEmailCheckSpinner: false,
      isFirstEmailCheck: true,
      showSearchClientSpinner: false,
    }
  },
  watch: {
    selectedRelation: {
      deep: true,
      handler(data) {
        if (data) {
          this.isFirstEmailCheck = false
          this.emailValid = true
          this.relation = data
        }
      },
    },
    selectedSupplyAddress: {
      deep: true,
      handler(data) {
        this.relation.postalCode = data.postalCode
        this.relation.houseNumber = data.houseNumber
        this.relation.houseNumberAddition = data.houseNumberAddition
        this.relation.street = data.street
        this.relation.city = data.city
      },
    },
  },
  methods: {
    getBusinessType(data) {
      if (data === 0) {
        return 1
      }
      if (data === 1) {
        return 2
      }

      return 1
    },
    confirmSelectSearschClient(relation) {
      this.$swal({
        title: 'Zoekresultaat',
        html: `De volgende klant is gevonden</br></br><strong>Naam: </strong>${getFullName(relation)}</br><strong>Adres: </strong>${relation.street} ${relation.houseNumber} ${relation.houseNumberAddition ?? ''}, ${relation.postalCode} ${relation.city}</br></br>`,
        icon: 'success',
        showCancelButton: true,
        confirmButtonText: 'Ja, gebruik deze relatie!',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$emit('set-relation', { relation, next: true })
        } else {
          this.clientSearchData.clientNumber = ''
          this.$refs.refFormSearchClientObserver.reset()
        }
      })
    },
    searchClient() {
      this.showSearchClientSpinner = true
      this.clientSearchData.productID = this.productId
      store
        .dispatch('apps-order-flow-checkout/clientNumberSearch', this.clientSearchData)
        .then(response => {
          if (response.data.success) {
            this.showSearchClientSpinner = false
            this.confirmSelectSearschClient(response.data.relation)
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Klant zoeken',
                icon: 'AlertTriangleIcon',
                variant: 'warning',
                text: 'Er is geen klant gevonden voor het opgegeven klantnummer.',
              },
            })
          }
          this.showSearchClientSpinner = false
        })
        .catch(() => {
          this.showSearchClientSpinner = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Klant zoeken',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het ophalen van de data. Probeer het later nog een keer.',
            },
          })
        })
    },
    checkEmail() {
      this.showEmailCheckSpinner = true
      this.emailValid = false
      store
        .dispatch('apps-order-flow-checkout/checkEmailDeployment', { email: this.relation.email })
        .then(response => {
          this.isFirstEmailCheck = false
          this.showEmailCheckSpinner = false
          if (response.data.success) {
            this.emailValid = true
          } else {
            this.emailValid = false
          }
        })
        .catch(() => {
          this.showEmailCheckSpinner = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Email valideren',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het ophalen van de data. Probeer het later nog een keer.',
            },
          })
        })
    },
    fetchCocResults() {
      const { query } = this

      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        store
          .dispatch('apps-order-flow-checkout/searchCocRegister', {
            q: query,
            sortBy: 'name',
          })
          .then(response => {
            this.suggestions = [{
              data: response.data,
            }]
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Kvk register',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: 'Er is een fout opgetreden bij het ophalen van de data. Probeer het later nog een keer.',
              },
            })
          })
      }, this.debounceMilliseconds)
    },
    getSuggestionValue(suggestion) {
      const { item } = suggestion
      return item.companyName
    },
    onSelected(value) {
      this.relation.companyCocNumber = value.cocNumber
      this.relation.companyName = value.companyName
    },
    getAddress() {
      const somethingChanged = []

      if (!this.relation.postalCode || !this.relation.houseNumber) {
        return
      }

      if (this.relation.postalCode) {
        somethingChanged.push(true)
      }

      if (this.relation.houseNumber) {
        somethingChanged.push(true)
      }

      if (this.relation.houseNumberAddition) {
        somethingChanged.push(true)
      }

      if (somethingChanged.includes(true)) {
        this.showAddressSpinner = true
        this.relation.street = null
        this.relation.city = null
        this.relation.province = null
        store.dispatch('apps-order-flow-checkout/fetchCorrespondenceAddress', this.relation)
          .then(response => {
            this.showAddressSpinner = false
            this.relation.street = response.data.street
            this.relation.city = response.data.city
            this.relation.province = response.data.province
            this.showAddressSpinner = false
          })
          .catch(() => {
            this.showAddressSpinner = false
          })
      }
    },
    onSubmit() {
      store.dispatch('apps-order-flow-checkout/setRelation', { relation: this.relation, productID: this.productId })
        .then(response => {
          this.$emit('set-relation', { relation: response.data, next: true })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Relatie',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het ophalen van de data. Probeer het later nog een keer.',
            },
          })
        })
    },
    stepBack() {
      this.$emit('back-step')
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      alphaNum,
      email,
      required,
      date,
      regex,
      integer,
      postalcode,
      iban,
      min,
      getFullName,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

[dir] .input-group-text {
  borderData: 1px solid #d8d6de !important;
}

.input-group-text {
  height: 38px !important;
}

 .usageSelected {
   background-color: rgba(255, 100, 0, 0.2);
 }
</style>
