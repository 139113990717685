<template>
  <div v-if="localOrderData.connections.length > 0">
    <b-row class="match-height">
      <b-col
        cols="12"
        md="7"
      >
        <b-card title="Correspondentieadres">
          <b-row>
            <b-col
              cols="12"
              md="5"
            >
              <validation-provider
                #default="validationContext"
                name="postalCode"
                rules=""
              >
                <b-form-group
                  label="Postcode"
                  label-for="postalCode"
                >
                  <b-form-input
                    id="postalCode"
                    v-model="relation.postalCode"
                    :state="getValidationState(validationContext)"
                    :disabled="true"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <validation-provider
                #default="validationContext"
                name="houseNumber"
                rules=""
              >
                <b-form-group
                  label="Huisnummer"
                  label-for="houseNumber"
                >
                  <b-form-input
                    id="houseNumber"
                    v-model="relation.houseNumber"
                    :state="getValidationState(validationContext)"
                    :disabled="true"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="houseNumberAddition"
                rules=""
              >
                <b-form-group
                  label="Huisnummertoevoeging"
                  label-for="houseNumberAddition"
                >
                  <b-form-input
                    id="houseNumberAddition"
                    v-model="relation.houseNumberAddition"
                    :state="getValidationState(validationContext)"
                    :disabled="true"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="street"
                rules=""
              >
                <b-form-group
                  label="Straat"
                  label-for="street"
                >
                  <b-form-input
                    id="street"
                    v-model="relation.street"
                    :state="getValidationState(validationContext)"
                    :disabled="true"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="city"
                rules=""
              >
                <b-form-group
                  label="Plaats"
                  label-for="city"
                >
                  <b-form-input
                    id="city"
                    v-model="relation.city"
                    :state="getValidationState(validationContext)"
                    :disabled="true"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Contactgegevens">
          <b-row>
            <b-col
              cols="12"
              md="12"
              class="mb-1"
            >
              <validation-provider
                #default="validationContext"
                name="gender"
                rules=""
              >
                <label>Geslacht</label>
                <div class="demo-inline-spacing">
                  <b-form-radio
                    v-model="relation.gender"
                    name="gender"
                    value="m"
                    class="mt-0"
                    :state="getValidationState(validationContext)"
                    :disabled="true"
                  >
                    Man
                  </b-form-radio>
                  <b-form-radio
                    v-model="relation.gender"
                    name="gender"
                    value="f"
                    class="mt-0"
                    :state="getValidationState(validationContext)"
                    :disabled="true"
                  >
                    Vrouw
                  </b-form-radio>
                </div>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="firstName"
                rules=""
              >
                <b-form-group
                  label="Voornaam"
                  label-for="firstName"
                >
                  <b-form-input
                    id="firstName"
                    v-model="relation.firstName"
                    :state="getValidationState(validationContext)"
                    :disabled="true"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Tussenvoegsel"
                label-for="middleName"
              >
                <b-form-input
                  id="middleName"
                  v-model="relation.middleName"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="5"
            >
              <validation-provider
                #default="validationContext"
                name="lastName"
                rules=""
              >
                <b-form-group
                  label="Achternaam"
                  label-for="lastName"
                >
                  <b-form-input
                    id="lastname"
                    v-model="relation.lastName"
                    :state="getValidationState(validationContext)"
                    :disabled="true"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="12"
            >
              <validation-provider
                #default="validationContext"
                name="birthDate"
                rules=""
              >
                <b-form-group
                  label="Geboortedatum"
                  label-for="birthDate"
                  :state="getValidationState(validationContext)"
                >
                  <flat-pickr
                    id="birthDate"
                    v-model="relation.birthDate"
                    :config="birthDateConfig"
                    :state="getValidationState(validationContext)"
                    :disabled="true"
                    :disable-mobile="true"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="phoneNumber"
                rules=""
              >
                <b-form-group
                  label="Telefoonnummer"
                  label-for="phoneNumber"
                >
                  <b-form-input
                    id="phoneNumber"
                    v-model="relation.phoneNumber"
                    type="text"
                    :state="getValidationState(validationContext)"
                    :disabled="true"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="mobileNumber"
                rules=""
              >
                <b-form-group
                  label="Mobiel nummer"
                  label-for="mobileNumber"
                >
                  <b-form-input
                    id="mobileNumber"
                    v-model="relation.mobileNumber"
                    type="text"
                    :state="getValidationState(validationContext)"
                    :disabled="true"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="12"
            >
              <validation-provider
                #default="validationContext"
                name="email"
                rules=""
              >
                <b-form-group
                  label="E-mailadres"
                  label-for="email"
                >
                  <b-form-input
                    id="email"
                    v-model="relation.email"
                    type="email"
                    :state="getValidationState(validationContext)"
                    :disabled="true"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-card>
        <b-card
          v-if="relation.type === 2"
          title="Bedrijfsgegevens"
        >
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="Bedrijfsnaam"
                rules=""
              >
                <b-form-group
                  label="Bedrijfsnaam"
                  label-for="companyName"
                >
                  <b-form-input
                    id="companyName"
                    v-model="relation.companyName"
                    :state="getValidationState(validationContext)"
                    :disabled="true"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="companyCocNumber"
                rules=""
              >
                <b-form-group
                  label="KVK-nummer"
                  label-for="companyCocNumber"
                >
                  <b-form-input
                    id="companyCocNumber"
                    v-model="relation.companyCocNumber"
                    :state="getValidationState(validationContext)"
                    :disabled="true"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="contactPerson"
                rules=""
              >
                <b-form-group
                  label="Contactpersoon"
                  label-for="contactPerson"
                >
                  <b-form-input
                    id="contactPerson"
                    v-model="relation.contactPerson"
                    :state="getValidationState(validationContext)"
                    :disabled="true"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Betaalgegevens">
          <b-row>
            <b-col
              cols="12"
              md="6"
              class="mb-1"
            >
              <validation-provider
                #default="validationContext"
                name="bankAccountNumber"
                rules=""
              >
                <b-form-group
                  label="IBAN"
                  label-for="bankAccountNumber"
                >
                  <b-form-input
                    v-model="relation.bankAccountNumber"
                    name="bankAccountNumber"
                    :state="getValidationState(validationContext)"
                    trim
                    :disabled="true"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="debtorName"
                rules=""
              >
                <b-form-group
                  label="Tenaamstelling"
                  label-for="ibanAscription"
                >
                  <b-form-input
                    id="debtorName"
                    v-model="relation.debtorName"
                    :state="getValidationState(validationContext)"
                    :disabled="true"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="5"
      >
        <b-overlay
          :show="showDraftCalculationSpinner"
        >
          <b-card
            :title="localOrderData.product && localOrderData.product.isGV ? 'Gecontracteerde tarieven' : 'Kostenspecificatie'"
          >
            <b-row
              v-if="showDraftCalculationSpinner"
            >
              <b-col
                style="min-height: 200px"
              >
                &nbsp;
              </b-col>
            </b-row>
            <hr>
            <b-row
              v-if="!showDraftCalculationSpinner"
            >
              <b-col
                cols="12"
              >
                <app-collapse
                  v-if="localDraftCalculation && localDraftCalculation.calculation"
                  accordion
                  type="margin"
                >
                  <div
                    v-for="item in localDraftCalculation.calculation"
                    :key="item.ean"
                  >
                    <app-collapse-item
                      :title="getDraftCalculationType(item.summary,item.ean).title"
                      :subject="item.ean"
                      :icon="getDraftCalculationType(item.summary,item.ean).icon"
                    >
                      <hr>
                      <b-row>
                        <b-col
                          cols="12"
                        >
                          <strong>{{ getProductNameForEAN() }}</strong>
                        </b-col>
                      </b-row>
                      <hr>
                      <template>
                        <b-row>
                          <b-col
                            cols="12"
                            class="mt-1"
                          >
                            <strong>Leveringskosten</strong>
                          </b-col>
                          <template
                            v-if="getDraftCalculationComponentIndex(item.calculation, 'Enkel',false) > 0"
                          >
                            <b-col
                              cols="8"
                            >
                              leveringskosten Enkeltarief
                            </b-col>
                            <b-col
                              cols="4"
                              class="text-right"
                            >
                              <span v-if="localOrderData.isBusiness === 0 || localOrderData.isSoho === 1">&euro; {{ parseFloat(item.calculation[getDraftCalculationComponentIndex(item.calculation, 'Enkel', false)].totalAmount).toFixed(2) }}</span>
                              <span v-if="localOrderData.isBusiness === 1 && localOrderData.isSoho === 0">&euro; {{ parseFloat(item.calculation[getDraftCalculationComponentIndex(item.calculation, 'Enkel', false)].amount).toFixed(2) }}</span>
                            </b-col>
                          </template>
                          <template
                            v-if="getDraftCalculationComponentIndex(item.calculation, 'Hoog', false) > 0"
                          >
                            <b-col
                              cols="8"
                            >
                              Leveringskosten Normaal
                            </b-col>
                            <b-col
                              cols="4"
                              class="text-right"
                            >
                              <span v-if="localOrderData.isBusiness === 0 || localOrderData.isSoho === 1">&euro; {{ parseFloat(item.calculation[getDraftCalculationComponentIndex(item.calculation, 'Hoog', false)].totalAmount).toFixed(2) }}</span>
                              <span v-if="localOrderData.isBusiness === 1 && localOrderData.isSoho === 0">&euro; {{ parseFloat(item.calculation[getDraftCalculationComponentIndex(item.calculation, 'Hoog', false)].amount).toFixed(2) }}</span>
                            </b-col>
                          </template>
                          <template
                            v-if="getDraftCalculationComponentIndex(item.calculation, 'Laag', false) > 0"
                          >
                            <b-col
                              cols="8"
                            >
                              Leveringskosten Dal
                            </b-col>
                            <b-col
                              cols="4"
                              class="text-right"
                            >
                              <span v-if="localOrderData.isBusiness === 0 || localOrderData.isSoho === 1">&euro; {{ parseFloat(item.calculation[getDraftCalculationComponentIndex(item.calculation, 'Laag', false)].totalAmount).toFixed(2) }}</span>
                              <span v-if="localOrderData.isBusiness === 1 && localOrderData.isSoho === 0">&euro; {{ parseFloat(item.calculation[getDraftCalculationComponentIndex(item.calculation, 'Laag', false)].amount).toFixed(2) }}</span>
                            </b-col>
                          </template>
                          <template
                            v-if="getDraftCalculationComponentIndex(item.calculation, 'Vastrecht', false) > 0"
                          >
                            <b-col
                              cols="8"
                            >
                              Vaste leveringskosten
                            </b-col>
                            <b-col
                              cols="4"
                              class="text-right"
                            >
                              <span v-if="localOrderData.isBusiness === 0 || localOrderData.isSoho === 1">&euro; {{ parseFloat(item.calculation[getDraftCalculationComponentIndex(item.calculation, 'Vastrecht', false)].totalAmount).toFixed(2) }}</span>
                              <span v-if="localOrderData.isBusiness === 1 && localOrderData.isSoho === 0">&euro; {{ parseFloat(item.calculation[getDraftCalculationComponentIndex(item.calculation, 'Vastrecht', false)].amount).toFixed(2) }}</span>
                            </b-col>
                          </template>
                          <template
                            v-if="getDraftCalculationComponentIndex(item.calculation, 'Netwerkkosten', false) > 0"
                          >
                            <b-col
                              cols="12"
                              class="mt-1"
                            >
                              <strong>Netbeheerkosten</strong>
                            </b-col>
                            <b-col
                              cols="8"
                            >
                              Netbeheerkosten
                            </b-col>
                            <b-col
                              cols="4"
                              class="text-right"
                            >
                              <span v-if="localOrderData.isBusiness === 0 || localOrderData.isSoho === 1">&euro; {{ parseFloat(item.calculation[getDraftCalculationComponentIndex(item.calculation, 'Netwerkkosten', false)].totalAmount).toFixed(2) }}</span>
                              <span v-if="localOrderData.isBusiness === 1 && localOrderData.isSoho === 0">&euro; {{ parseFloat(item.calculation[getDraftCalculationComponentIndex(item.calculation, 'Netwerkkosten', false)].amount).toFixed(2) }}</span>
                            </b-col>
                          </template>
                          <b-col
                            cols="12"
                            class="mt-1"
                          >
                            <strong>Belasting en toeslagen</strong>
                          </b-col>
                          <section
                            v-for="energyTaxItem in getCalculationComponents(item.calculation,'Energiebelasting', false)"
                            :key="energyTaxItem.componentType + String(Math.random())"
                            style="width:100%;display: flex"
                          >
                            <b-col
                              cols="8"
                            >
                              Energiebelasting ({{ energyTaxItem.rangeStart }} - {{ energyTaxItem.rangeEnd }} {{ energyTaxItem.priceUnit }})
                            </b-col>
                            <b-col
                              cols="4"
                              class="text-right"
                            >
                              <span v-if="localOrderData.isBusiness === 0 || localOrderData.isSoho === 1">&euro; {{ parseFloat(energyTaxItem.totalAmount).toFixed(2) }}</span>
                              <span v-if="localOrderData.isBusiness === 1 && localOrderData.isSoho === 0">&euro; {{ parseFloat(energyTaxItem.amount).toFixed(2) }}</span>
                            </b-col>
                          </section>
                          <section
                            v-for="energyTaxItem in getCalculationComponents(item.calculation,'Opslag duurzame energie', false)"
                            :key="energyTaxItem.componentType + String(Math.random())"
                            style="width:100%;display: flex"
                          >
                            <b-col
                              cols="8"
                            >
                              Opslag duurzame energie ({{ energyTaxItem.rangeStart }} - {{ energyTaxItem.rangeEnd }} {{ energyTaxItem.priceUnit }})
                            </b-col>
                            <b-col
                              cols="4"
                              class="text-right"
                            >
                              <span v-if="localOrderData.isBusiness === 0 || localOrderData.isSoho === 1">&euro; {{ parseFloat(energyTaxItem.totalAmount).toFixed(2) }}</span>
                              <span v-if="localOrderData.isBusiness === 1 && localOrderData.isSoho === 0">&euro; {{ parseFloat(energyTaxItem.amount).toFixed(2) }}</span>
                            </b-col>
                          </section>
                          <template
                            v-if="getDraftCalculationComponentIndex(item.calculation, 'Heffingskorting', false) > 0"
                          >
                            <b-col
                              cols="8"
                            >
                              Vermindering energiebelasting
                            </b-col>
                            <b-col
                              cols="4"
                              class="text-right"
                            >
                              <span v-if="localOrderData.isBusiness === 0 || localOrderData.isSoho === 1">&euro; {{ parseFloat(item.calculation[getDraftCalculationComponentIndex(item.calculation, 'Heffingskorting', false)].totalAmount).toFixed(2) }}</span>
                              <span v-if="localOrderData.isBusiness === 1 && localOrderData.isSoho === 0">&euro; {{ parseFloat(item.calculation[getDraftCalculationComponentIndex(item.calculation, 'Heffingskorting', false)].amount).toFixed(2) }}</span>
                            </b-col>
                          </template>
                          <b-col
                            cols="12"
                          >
                            <hr>
                          </b-col>
                          <b-col
                            cols="8"
                          >
                            <strong>Totaal</strong>
                          </b-col>
                          <b-col
                            cols="4"
                            class="text-right"
                          >
                            <span v-if="localOrderData.isBusiness === 0 || localOrderData.isSoho === 1">&euro; {{ parseFloat(item.summary.totalAmount).toFixed(2) }}</span>
                            <span v-if="localOrderData.isBusiness === 1 && localOrderData.isSoho === 0">&euro; {{ parseFloat(item.summary.totalSubAmount).toFixed(2) }}</span>
                          </b-col>
                        </b-row>
                      </template>
                    </app-collapse-item>
                  </div>
                </app-collapse>
                <app-collapse
                  v-if="orderData.product"
                  accordion
                  type="margin"
                >
                  <app-collapse-item
                    title="Uw tarieven"
                    subject="(exclusief btw)"
                    icon="fas fa-euro-sign"
                  >
                    <hr>
                    <b-row
                      v-if="orderData.product.type === 'DUAL' || orderData.product.type === 'ELECTRICITY'"
                    >
                      <b-col
                        cols="12"
                        class="mt-1"
                      >
                        <strong>Elektriciteit</strong>
                      </b-col>
                      <b-col
                        cols="8"
                        style="margin-top:5px"
                      >
                        Enkeltarief
                      </b-col>
                      <b-col
                        cols="4"
                        class="text-right"
                      >
                        <span>&euro;{{ localOrderData.product.currentTariff.tariffSingle }} /kWh</span>
                      </b-col>
                      <b-col
                        cols="8"
                      >
                        Normaaltarief
                      </b-col>
                      <b-col
                        cols="4"
                        class="text-right"
                      >
                        <span>&euro;{{ localOrderData.product.currentTariff.tariffHigh }} /kWh</span>
                      </b-col>
                      <b-col
                        cols="8"
                      >
                        Daltarief
                      </b-col>
                      <b-col
                        cols="4"
                        class="text-right"
                      >
                        <span>&euro;{{ localOrderData.product.currentTariff.tariffLow }} /kWh</span>
                      </b-col>
                      <b-col
                        cols="8"
                      >
                        Teruglevering enkeltarief
                      </b-col>
                      <b-col
                        cols="4"
                        class="text-right"
                      >
                        <span>&euro;{{ localOrderData.product.currentTariff.tariffReturnSingle }} /kWh</span>
                      </b-col>
                      <b-col
                        cols="8"
                      >
                        Teruglevering normaaltarief
                      </b-col>
                      <b-col
                        cols="4"
                        class="text-right"
                      >
                        <span>&euro;{{ localOrderData.product.currentTariff.tariffReturnHigh }} /kWh</span>
                      </b-col>
                      <b-col
                        cols="8"
                      >
                        Teruglevering daltarief
                      </b-col>
                      <b-col
                        cols="4"
                        class="text-right"
                      >
                        <span>&euro;{{ localOrderData.product.currentTariff.tariffReturnLow }} /kWh</span>
                      </b-col>
                      <b-col
                        cols="8"
                      >
                        Vaste kosten
                      </b-col>
                      <b-col
                        cols="4"
                        class="text-right"
                      >
                        <span>&euro;{{ localOrderData.product.currentTariff.tariffFixedEnergy }} /jaar</span>
                      </b-col>
                    </b-row>
                    <b-row v-if="orderData.product.type === 'DUAL' || orderData.product.type === 'GAS'">
                      <b-col
                        cols="12"
                        class="mt-1"
                      >
                        <strong>Gas</strong>
                      </b-col>
                      <b-col
                        cols="8"
                        style="margin-top:5px"
                      >
                        Leveringstarief (G1)
                      </b-col>
                      <b-col
                        cols="4"
                        class="text-right"
                      >
                        <span>&euro;{{ localOrderData.product.currentTariff.tariffGas1 }} /m3</span>
                      </b-col>
                      <b-col
                        cols="8"
                      >
                        Leveringstarief (G2)
                      </b-col>
                      <b-col
                        cols="4"
                        class="text-right"
                      >
                        <span>&euro;{{ localOrderData.product.currentTariff.tariffGas1 }} /m3</span>
                      </b-col>
                      <b-col
                        cols="8"
                      >
                        Vaste kosten
                      </b-col>
                      <b-col
                        cols="4"
                        class="text-right"
                      >
                        <span>&euro;{{ localOrderData.product.currentTariff.tariffFixedGas }} /jaar</span>
                      </b-col>
                    </b-row>
                  </app-collapse-item>
                </app-collapse>
              </b-col>
            </b-row>
            <b-row
              v-if="localDraftCalculation && localDraftCalculation.calculation"
              class="mt-3"
              style="border-radius:5px;background-color:#fb8500;color:#ffffff;padding-top: 10px;padding-bottom: 10px"
            >
              <b-col
                cols="8"
                style="padding-top:5px"
              >
                <strong>Jaarlijkse kosten ({{ localOrderData.isBusiness === 0 || localOrderData.isSoho === 1 ? 'incl.' : 'excl.' }} btw)</strong>
              </b-col>
              <b-col
                cols="4"
                style="padding-top:5px"
              >
                <span v-if="localOrderData.isBusiness === 0 || localOrderData.isSoho === 1"><strong>&euro; {{ parseFloat((localDraftCalculation.calculationSummary.totalAmount * 12)).toFixed(2) }}</strong></span>
                <span v-if="localOrderData.isBusiness === 1 && localOrderData.isSoho === 0"><strong>&euro; {{ parseFloat((localDraftCalculation.calculationSummary.totalSubAmount * 12)).toFixed(2) }}</strong></span>
              </b-col>
              <b-col
                cols="8"
                style="padding-top:5px"
              >
                <strong>Maandelijkse kosten ({{ orderData.isBusiness === 0 || localOrderData.isSoho === 1 ? 'incl.' : 'excl.' }} btw)</strong>
              </b-col>
              <b-col
                cols="4"
                style="padding-top:5px"
              >
                <span v-if="orderData.isBusiness === 0 || localOrderData.isSoho === 1"><strong>&euro; {{ parseFloat((localDraftCalculation.calculationSummary.totalAmount)).toFixed(2) }}</strong></span>
                <span v-if="orderData.isBusiness === 1 && localOrderData.isSoho === 0"><strong>&euro; {{ parseFloat((localDraftCalculation.calculationSummary.totalSubAmount)).toFixed(2) }}</strong></span>
              </b-col>
              <b-col
                cols="8"
                style="padding-top:5px"
              >
                <strong>btw</strong>
              </b-col>
              <b-col
                cols="4"
                style="padding-top:5px"
              >
                <strong>&euro; {{ parseFloat((localDraftCalculation.calculationSummary.totalVatAmount)).toFixed(2) }}</strong>
              </b-col>
            </b-row>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col
        v-if="electricityEans.length > 0"
        cols="12"
        md="12"
      >
        <b-card
          title="Elektriciteit EAN's"
        >
          <b-table
            ref="refElectricityEANTable"
            class="position-relative"
            :items="electricityEans"
            responsive
            :fields="electricityEanTableColumns"
            primary-key="id"
            show-empty
            empty-text="Geen overeenkomende records gevonden"
          >
            <template #cell(ean)="data">
              <small>{{ data.value }}</small>
            </template>
            <template #cell(bagPurpose)="data">
              <small>{{ data.value && data.item.usageType !== 'LARGECONSUMER' ? data.value : '' }}</small>
            </template>
            <template #cell(marketSegment)="data">
              <small>{{ data.value === 'ELECTRICITY' ? 'Elektriciteit' : 'Gas' }} {{ getUsageType(data.item.usageType) }}</small><br>
              <small>Inhuizing: {{ data.item.switchType === 'SWITCH' ? 'Nee' : '' }}{{ data.item.switchType === 'MOVEIN' ? 'Ja' : '' }}</small>
            </template>
            <template #cell(meterType)="data">
              <small>{{ data.value === 'SINGLE' ? 'Enkele meter' : 'Dubbele meter' }}</small>
            </template>
            <template #cell(postalCode)="data">
              <span class="font-weight-bold d-block">
                <small>{{ data.item.street }} {{ data.item.houseNumber }} {{ houseNumberAddition?'houseNumberAddition':'' }}</small>
              </span>
              <small class="text-muted">{{ data.item.postalCode }}, {{ data.item.city }}</small>
            </template>
            <template #cell(usage)="data">
              <span
                v-if="data.item.marketSegment === 'ELECTRICITY' && data.item.meterType === 'SINGLE'"
                class="font-weight-bold d-block"
              >
                <small style="width:100%">Enkel: {{ data.item.usageElectricitySingle }}</small>
              </span>
              <span
                v-if="data.item.marketSegment === 'ELECTRICITY' && data.item.meterType !== 'SINGLE'"
                class="font-weight-bold d-block"
              >
                <small style="width:100%">Normaal: {{ data.item.usageElectricityHigh }}</small>
              </span>
              <span
                v-if="data.item.marketSegment === 'ELECTRICITY' && data.item.meterType !== 'SINGLE'"
                class="font-weight-bold d-block"
              >
                <small style="width:100%">Dal: {{ data.item.usageElectricityLow }}</small>
              </span>
              <span
                v-if="data.item.marketSegment === 'GAS'"
                class="font-weight-bold d-block"
              >
                <small style="width:100%">{{ data.item.usageGas }} m3</small>
              </span>
            </template>
            <template #cell(returnElectricitySingle)="data">
              <span
                v-if="data.item.returnElectricitySingle !== null && data.item.returnElectricitySingle !== 0 && data.item.meterType === 'SINGLE'"
                class="font-weight-bold d-block"
              >
                <small style="width:100%">Enkel: {{ data.item.returnElectricitySingle }}</small>
              </span>
              <span
                v-if="data.item.returnElectricityHigh !== null && data.item.returnElectricityHigh !== 0 && data.item.meterType !== 'SINGLE'"
                class="font-weight-bold d-block"
              >
                <small style="width:100%">Normaal: {{ data.item.returnElectricityHigh }}</small>
              </span>
              <span
                v-if="data.item.returnElectricityLow !== null && data.item.returnElectricityLow !== 0 && data.item.meterType !== 'SINGLE'"
                class="font-weight-bold d-block"
              >
                <small style="width:100%">Dal: {{ data.item.returnElectricityLow }}</small>
              </span>
            </template>
          </b-table>
        </b-card>
      </b-col>
      <b-col
        v-if="gasEans.length > 0"
        cols="12"
        md="12"
      >
        <b-card
          title="Gas EAN's"
        >
          <b-table
            ref="refGasEANTable"
            class="position-relative"
            :items="gasEans"
            responsive
            :fields="GasTableColumns"
            primary-key="id"
            show-empty
            empty-text="Geen overeenkomende records gevonden"
          >
            <template #cell(ean)="data">
              <small>{{ data.value }}</small>
            </template>
            <template #cell(bagPurpose)="data">
              <small>{{ data.value && data.item.usageType !== 'LARGECONSUMER' ? data.value : '' }}</small>
            </template>
            <template #cell(marketSegment)="data">
              <small>{{ data.value === 'GAS' ? 'Gas' : 'Elektriciteit' }} {{ getUsageType(data.item.usageType) }}</small><br>
              <small>Inhuizing: {{ data.item.switchType === 'SWITCH' ? 'Nee' : '' }}{{ data.item.switchType === 'MOVEIN' ? 'Ja' : '' }}</small>
            </template>
            <template #cell(meterType)="data">
              <small>{{ data.value === 'SINGLE' ? 'Enkele meter' : 'Dubbele meter' }}</small>
            </template>
            <template #cell(usage)="data">
              <span
                class="font-weight-bold d-block"
              >
                <small style="width:100%">{{ data.item.usageGas }} m3</small>
              </span>
            </template>
            <template #cell(status)="data">
              <small>{{ getStatusType(data.value) }}</small>
            </template>
          </b-table>
        </b-card>
      </b-col>
      <b-col
        v-if="ChargingPoints.length > 0 && chargingPointEnabled === 'true'"
        cols="12"
        md="12"
      >
        <b-card
          title="Laadpalen"
        >
          <b-table
            ref="refChargingStationTable"
            class="position-relative"
            :items="ChargingPoints"
            responsive
            :fields="chargingStationTableColumns"
            primary-key="id"
            show-empty
            empty-text="Geen overeenkomende records gevonden"
          >
            <template #cell(chargingPointID)="data">
              <small>{{ data.value }}</small>
            </template>
            <template #cell(chargingPointCarType)="data">
              <small>{{ getChargingPointCarType(data.value) }}</small>
            </template>
            <template #cell(chargingPointPeriodType)="data">
              <small>{{ getChargingPointPeriod(data.value) }}</small>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-row
      class="match-height"
    >
      <b-col
        cols="12"
        md="7"
      >
        <b-card
          title="Ordergegevens"
        >
          <validation-observer
            ref="refFormObserver"
          >
            <b-form
              class="mb-2"
            >
              <b-row class="mb-1">
                <b-col
                  cols="12"
                  md="12"
                >
                  <validation-provider
                    #default="validationContext"
                    name="isRenewal"
                    rules="required"
                  >
                    <label>Verlenging</label>
                    <div class="demo-inline-spacing">
                      <b-form-radio
                        v-model="isRenewal"
                        name="isRenewal"
                        :value="0"
                        class="mt-0"
                        :state="getValidationState(validationContext)"
                        @input="resetRenewalType"
                      >
                        Nee
                      </b-form-radio>
                      <b-form-radio
                        v-model="isRenewal"
                        name="isRenewal"
                        :value="1"
                        class="mt-0"
                        :state="getValidationState(validationContext)"
                        @input="resetStartDate"
                      >
                        Ja
                      </b-form-radio>
                    </div>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col
                  cols="12"
                  md="12"
                >
                  <validation-provider
                    #default="validationContext"
                    name="renewalType"
                    rules="required_if:isRenewal,1"
                  >
                    <label>Verlengingstype</label>
                    <div class="demo-inline-spacing">
                      <b-form-radio
                        v-model="renewalType"
                        name="renewalType"
                        value="CONTRACT"
                        class="mt-0"
                        :state="getValidationState(validationContext)"
                        :disabled="!isRenewal"
                        @input="resetStartDate"
                      >
                        Contractverlenging
                      </b-form-radio>
                      <b-form-radio
                        v-model="renewalType"
                        name="renewalType"
                        value="VARIABLECONTRACT"
                        class="mt-0"
                        :state="getValidationState(validationContext)"
                        :disabled="!isRenewal"
                      >
                        Variabel naar Vast
                      </b-form-radio>
                    </div>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="startDateContract"
                    rules="required_if:isRenewal,0|required_if:renewalType,VARIABLECONTRACT,null"
                  >
                    <b-form-group
                      label="Overstapdatum"
                      label-for="startDateContract"
                      :state="getValidationState(validationContext)"
                    >
                      <flat-pickr
                        id="startDateContract"
                        v-model="startDate"
                        :config="startDateContractConfig"
                        :state="getValidationState(validationContext)"
                        :disabled="renewalType==='CONTRACT'"
                        :disable-mobile="renewalType==='CONTRACT'"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
      <b-col
        v-if="selectedFlow.canSignSignature"
        cols="12"
        md="5"
      >
        <b-card
          no-body
        >
          <b-card-header>
            <b-card-title>Handtekening: <small class="text-secondary">{{ orderData.contact ? getFullName(orderData.contact) : '' }}</small></b-card-title>
          </b-card-header>
          <b-card-body>
            <VueSignaturePad
              ref="signaturePad"
              :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()}}"
              :class="this.signatureCheck && !this.signatureCheck.isEmpty ? 'signature':'signature invalid'"
              width="340px"
              height="150px"
            />
            <div class="mt-2 mb-1">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                @click="undo()"
              ><span>Opnieuw</span></b-button>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
      >
        <b-card>
          <b-row>
            <b-col
              cols="6 text-left"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                @click="$emit('back-step')"
              >
                <span>Vorige</span>
              </b-button>
            </b-col>
            <b-col
              cols="6 text-right"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                :disabled="showSubmitOrderSpinner"
                @click="onsubmit"
              >
                <b-spinner
                  v-if="showSubmitOrderSpinner"
                  small
                  class="mr-1"
                />
                <span>Aanmaken</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCol,
  BRow,
  BButton,
  BTable,
  BSpinner, BFormGroup, BFormInput, BFormInvalidFeedback, BFormRadio, BForm, BOverlay,
} from 'bootstrap-vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { avatarText, formatDate } from '@core/utils/filter'
import { getFullName, todayMinYears } from '@core/utils/utils'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import useOrderFlow from '@/views/pages/order-flow/useOrderFlow'
import router from '@/router'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import errorDefaultMessage from '@core/app-config/errorAndSuccesDefaultMessage'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BTable,
    BSpinner,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormRadio,
    flatPickr,
    BForm,
    AppCollapse,
    AppCollapseItem,
    BOverlay,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    orderData: {
      type: Object,
      required: true,
    },
    relation: {
      type: Object,
      required: true,
    },
    selectedFlow: {
      type: Object,
      required: true,
    },
    draftCalculation: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chargingPointEnabled: process.env.VUE_APP_CHARGINGPOINT,
      localOrderData: {
        productID: null,
        chargingPointProductID: null,
        relationID: null,
        connections: [],
        flowID: null,
        signatureSVG: null,
        signType: null,
        isBusiness: 0,
        isSoho: 0,
      },
      startDate: null,
      isRenewal: 0,
      renewalType: null,
      signatureError: false,
      showSubmitOrderSpinner: false,
      showOverlay: false,
      birthDateConfig: {
        maxDate: todayMinYears(18),
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
        altInput: true,
        allowInput: true,
      },
      yesNoOptions: [
        { label: 'Ja', value: true },
        { label: 'Nee', value: false },
      ],
      gasEans: [],
      electricityEans: [],
      ChargingPoints: [],
      signatureCheck: null,
      localDraftCalculation: null,
      showDraftCalculationSpinner: true,
      startDateContractConfig: {
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
        altInput: true,
        allowInput: true,
        minDate: null,
        maxDate: null,
      },
    }
  },
  watch: {
    draftCalculation: {
      deep: true,
      async handler(data) {
        if (data.length > 0) {
          this.showDraftCalculationSpinner = true
          // eslint-disable-next-line prefer-destructuring
          this.localDraftCalculation = data.filter(calc => calc.product.id === this.orderData.productID)[0]
          this.showDraftCalculationSpinner = false
        }
      },
    },
    orderData: {
      deep: true,
      handler(data) {
        this.localOrderData = data
        this.electricityEans = data.connections.filter(connection => connection.marketSegment === 'ELECTRICITY' && !connection.isChargingPoint)
        this.gasEans = data.connections.filter(connection => connection.marketSegment === 'GAS')
        this.ChargingPoints = data.connections.filter(connection => connection.marketSegment === 'ELECTRICITY' && connection.type === 'CHARGINGPOINT')
        // eslint-disable-next-line prefer-destructuring
        if (data.productID) {
          // eslint-disable-next-line prefer-destructuring
          this.localDraftCalculation = this.draftCalculation.filter(calc => calc.product.id === data.productID)[0]
          this.startDateContractConfig.minDate = new Date(
            new Date(new Date().setDate(new Date().getDate() + this.localDraftCalculation.product.switchWindowMinDays)).getFullYear(),
            new Date(new Date().setDate(new Date().getDate() + this.localDraftCalculation.product.switchWindowMinDays)).getMonth(),
            new Date(new Date().setDate(new Date().getDate() + this.localDraftCalculation.product.switchWindowMinDays)).getDate(),
          )
          this.startDateContractConfig.maxDate = new Date().setDate(new Date().getDate() + this.localDraftCalculation.product.switchWindowsMaxDays)
        }
      },
    },
  },
  methods: {
    resetStartDate() {
      this.startDate = null
    },
    resetRenewalType() {
      this.renewalType = null
    },
    getCalculationComponents(calculation, componentType) {
      return calculation.filter(comp => comp.componentType === componentType)
    },
    getDraftCalculationComponentIndex(calculation, componentType, isReturn) {
      let data = null

      if (isReturn) {
        data = calculation.findIndex(comp => comp.componentType === componentType && comp.isReturn)
      } else {
        data = calculation.findIndex(comp => comp.componentType === componentType && !comp.isReturn)
      }

      return data
    },
    getDraftCalculationType(summary, ean) {
      if (summary.marketSegment === 'ELECTRICITY' && !this.isChargingPoint(ean)) {
        return { title: 'Stroom', icon: 'fas fa-bolt' }
      }

      if (summary.marketSegment === 'ELECTRICITY' && this.isChargingPoint(ean)) {
        return { title: 'Stroom', icon: 'fas fa-charging-station' }
      }

      if (summary.marketSegment === 'GAS') {
        return { title: 'Gas', icon: 'fas fa-burn' }
      }

      return ''
    },
    isChargingPoint(ean) {
      return this.localOrderData.connections.some(obj => obj.isChargingPoint === true && obj.ean === ean)
    },
    getProductNameForEAN(ean) {
      if (this.isChargingPoint(ean)) {
        return this.localDraftCalculation.chargingProduct.name
      }
      return this.localDraftCalculation.product.name
    },
    undo() {
      this.$refs.signaturePad.undoSignature()
    },
    validateSignature() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature('image/svg+xml')
      return { isEmpty, data }
    },
    stepBack() {
      this.$emit('set-order', this.orderData)
      this.$emit('back-step')
    },
    async onsubmit() {
      if (this.selectedFlow.canSignSignature) {
        this.signatureCheck = this.validateSignature()
      }
      const orderValidators = [this.$refs.refFormObserver.validate()]
      await Promise.all(orderValidators).then(valid => {
        if (!valid.includes(false)) {
          this.showSubmitOrderSpinner = true
          if (this.selectedFlow.canSignSignature && !this.signatureCheck.isEmpty) {
            this.localOrderData.signatureSVG = this.signatureCheck.data
          }
          this.localOrderData.connections.forEach(connection => {
            connection.startDate = this.startDate
          })

          this.localOrderData.isRenewal = this.isRenewal
          this.localOrderData.renewalType = this.renewalType

          store.dispatch('apps-order-flow-checkout/createOrders', this.localOrderData).then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Order',
                icon: 'AlertTriangleIcon',
                variant: 'succes',
                text: 'Uw order is met succes aangemaakt.',
              },
            })
            this.showSubmitOrderSpinner = false
            router.push({ name: 'app-order' })
          }).catch(() => {
            this.showSubmitOrderSpinner = false
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Order',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: `${errorDefaultMessage.saveDataError}`,
              },
            })
          })
        }
      })
    },
  },
  setup() {
    const {
      refElectricityEANTable,
      electricityEanTableColumns,
      refetchElectricityEANData,
      refGasTable,
      GasTableColumns,
      refetchGasEANData,
      refChargingStationTable,
      refetchChargingStationData,
      chargingStationTableColumns,
      getUsageType,
      getStatusType,
      getChargingPointPeriod,
      getChargingPointCarType,
    } = useOrderFlow()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      refElectricityEANTable,
      electricityEanTableColumns,
      refetchElectricityEANData,
      refGasTable,
      GasTableColumns,
      refetchGasEANData,
      refFormObserver,
      getValidationState,
      resetForm,
      avatarText,
      getFullName,
      refChargingStationTable,
      refetchChargingStationData,
      chargingStationTableColumns,
      getUsageType,
      getStatusType,
      formatDate,
      getChargingPointPeriod,
      getChargingPointCarType,
    }
  },
}
</script>
<style lang="scss" scoped>
.signature {
  border: solid 2px rgb(255, 100, 0);
  border-radius: 5px;
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.signature.invalid {
  border: solid 2px #ea5455;
}

.invalid{
  .card-title{
    color: #ea5455
  }
}

.form-group.is-invalid {
  ::v-deep .form-control {
    border-color: #ea5455 !important;
  }
}
</style>
